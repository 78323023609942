<template>
  <div class="container p-5">
    <div class="row justify-content-md-center">
      <div col-12>
        <ul class="nav justify-content-center">
          <li class="nav-item">
            <a class="nav-link" href="https://nonfungibletime.io">
              <h4 class="text-white text-opacity-75 futurism">
                PAST
              </h4>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://tempoland.xyz">
              <h4 class="text-white text-opacity-75 futurism">
                PRESENT
              </h4>
            </a>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '#', replace: true }">
              <h4 class="text-white text-opacity-75 futurism">
                FUTURE
              </h4>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="container-fluid border-top border-bottom border-1 border-light">
  </div>

  <div class="container p-5">
    <div class="row justify-content-md-center">
      <div col-12>
        <ul class="nav justify-content-center">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '#' }">
              <h4 class="text-white text-opacity-75 sofiaProBlack">
                © CROONOS LAB SL.
              </h4>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '#' }">
              <h4 class="text-white text-opacity-75 sofiaProBlack">
                TERM AND CONDITIONS
              </h4>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '#', replace: true }">
              <h4 class="text-white text-opacity-75 sofiaProBlack">
                NFT PURCHASE AGREEMENT
              </h4>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  onBeforeMount,
  onMounted,
  // reactive,
} from 'vue';
// import Animation from '@/composables/Animate';

/* const animacionesTextFooter = async () => {
  const titlesHome = document.querySelectorAll('.anime-titles-footer');
  titlesHome.forEach((text) => {
    Animation.WordShuffler(
      text,
      {
        textColor: 'rgba(255,255,255,0.7)',
        timeOffset: 2,
        mixCapital: true,
        mixSpecialCharacters: true,
      },
      true,
    );
  });
  const parrafoHome = document.querySelectorAll('.anime-text-footer');
  parrafoHome.forEach((text) => {
    Animation.WordShuffler(
      text,
      {
        textColor: 'rgba(255,255,255,0.7)',
        timeOffset: 1,
      },
      true,
    );
  });
};
 */
onBeforeMount(() => {
});

onMounted(() => {
});
</script>

<style scoped lang="scss">
.border-warning {
  --bs-border-opacity: 0.5 !important;
  --bs-warning-rgb: 255, 255, 0;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}
</style>
