<template>
  <div class="w-100 m-0 p-0 position-relative"
    style="margin-top: -1.2rem;"
  >
    <div class="w-100 position-absolute">
      <div class="row d-none d-sm-block" style="z-index: 10;">
        <div class="col-12 p-0">
          <div ref="sliderI" class="keen-slider">
            <div class="keen-slider__slide" style="min-width: 1235px; max-width: 1235px">
              <figure class="figure">
                <img src="../assets/images/a_calendar_to_make_history.svg"
                  class="figure-img img-fluid" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="keen-slider__slide" style="min-width: 1235px; max-width: 1235px">
              <figure class="figure">
                <img src="../assets/images/a_calendar_to_make_history.svg"
                  class="figure-img img-fluid" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="keen-slider__slide" style="min-width: 1235px; max-width: 1235px">
              <figure class="figure">
                <img src="../assets/images/a_calendar_to_make_history.svg"
                  class="figure-img img-fluid" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="keen-slider__slide" style="min-width: 1235px; max-width: 1235px">
              <figure class="figure">
                <img src="../assets/images/a_calendar_to_make_history.svg"
                  class="figure-img img-fluid" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="tempoLand" class="w-100 m-0 position-static">
      <div class="w-100 h-100 position-absolute">
        <div class="container px-5 h-100">
          <div class="row justify-content-center align-items-center h-50">
            <div class="col-11 col-md-9 mt-2 mt-sm-4 px-md-5">
              <div class="row">
                <div v-if="props.leng === 'zh'" class="col-12 px-0 text-white text-opacity-75">
                  <p
                    class="sofiaExtraLight text-justify anime-tempoland"
                   >
                    Tempoland是一个创新的、动画的、实时的、生成性的螺旋日历，显示着一整个世纪。
                  </p>
                  <p
                     class="sofiaExtraLight pt-3 text-justify anime-tempoland"
                   >
                    每一天都是一个惊喜的盒子，它的维度和用处将根据我们的需求和我们对它的关注而变化
                  </p>
                  <p
                    class="sofiaExtraLight pt-3 text-justify anime-tempoland"
                   >
                    在Tempoland日历中占据你的位置，并在区块链上永久记录你在这个新宇宙时空的承诺。
                  </p>
                </div>
                <div v-else class="col-12 px-0 text-white text-opacity-75">
                  <p
                    class="sofiaExtraLight text-justify anime-tempoland"
                   >
                    Tempoland is an innovative, animated, live and generative spiral
                    calendar displaying a whole century.
                  </p>
                  <p
                     class="sofiaExtraLight pt-3 text-justify anime-tempoland"
                   >
                    Every new day is a surprise box whose dimensions and usefulness will
                    change according to our needs and the attention we pay to it.
                  </p>
                  <p
                    class="sofiaExtraLight pt-3 text-justify anime-tempoland"
                   >
                    Take your place in Tempoland calendar and record permanently on the
                    blockchain your commitment to a space-time in this new universe.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 position-static">
        <video autoplay playsinline muted preload="metadata" loop
          poster="../assets/videos/master_2000X1720.png">
          <source src="../assets/videos/master_2000X1720.mp4" type="video/mp4">
          Su navegador no soporta contenido multimedia.
          <track src="" kind="captions" srclang="es" label="">
        </video>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <div class="p-2" style="position: relative; z-index: 100;">
        <a
          href="https://cronos-calendar.web.app"
          class="link-secondary link-offset-2
            link-underline-opacity-0
            link-underline-opacity-25-hover
            pe-5 futurism"
            >VIEW CALENDAR</a>
      </div>
    </div>
  </div>

  <div class="container p-5"
    @mouseover="rAudio()"
    @focus="rAudio()"
  >
    <div class="card bg-black">
      <div class="card-body p-0">
        <div v-if="props.leng === 'zh'" class="row pt-5 pb-5">
          <div
            class="col-12 p-0 fs-1 fw-bold text-white text-opacity-75
              futurism anime-tempoland"
          >
            <h1>
              36.525 <span class="text-white text-opacity-50">天</span>
            </h1>
          </div>
          <div
            class="col-12 p-0 fs-1 fw-bold text-white text-opacity-75
              futurism anime-tempoland"
          >
            <h1>
              1.200 <span class="text-white text-opacity-50">个月</span>
            </h1>
          </div>
          <div
            class="col-12 p-0 fs-1 fw-bold text-white text-opacity-75
              futurism anime-tempoland"
          >
            <h1>
              100 <span class="text-white text-opacity-50">年</span>
            </h1>
          </div>
        </div>
        <div v-else class="row pt-5 pb-5">
          <div
            class="col-12 p-0 fs-1 fw-bold text-white text-opacity-75
              futurism anime-tempoland"
          >
            <h1>
              36.525 <span class="text-white text-opacity-50">Days</span>
            </h1>
          </div>
          <div
            class="col-12 p-0 fs-1 fw-bold text-white text-opacity-75
              futurism anime-tempoland"
          >
            <h1>
              1.200 <span class="text-white text-opacity-50">Months</span>
            </h1>
          </div>
          <div
            class="col-12 p-0 fs-1 fw-bold text-white text-opacity-75
              futurism anime-tempoland"
          >
            <h1>
              100 <span class="text-white text-opacity-50">Years</span>
            </h1>
          </div>
        </div>
        <div class="row pt-5">
          <div class="col-md-4 col-12 p-3 anime-tempoland">
            <video autoplay playsinline muted preload="metadata" loop
              poster="../assets/videos/generic_days_borde_azul.png">
              <source src="../assets/videos/generic_days_borde_azul.mp4" type="video/mp4">
              Su navegador no soporta contenido multimedia.
              <track src="" kind="captions" srclang="es" label="">
            </video>
            <p class="text-white text-opacity-50 text-center futurism pt-3">
              DAY
            </p>
          </div>
          <div class="col-md-4 col-12 p-3 anime-tempoland">
            <video autoplay playsinline muted preload="metadata" loop
              poster="../assets/videos/generic_months.png">
              <source src="../assets/videos/generic_months.mp4" type="video/mp4">
              Su navegador no soporta contenido multimedia.
              <track src="" kind="captions" srclang="es" label="">
            </video>
            <p class="text-white text-opacity-50 text-center futurism pt-3">
              MONTH %
            </p>
          </div>
          <div class="col-md-4 col-12 p-3 anime-tempoland">
            <video autoplay playsinline muted preload="metadata" loop
              poster="../assets/videos/generic_years.png">
              <source src="../assets/videos/generic_years.mp4" type="video/mp4">
              Su navegador no soporta contenido multimedia.
              <track src="" kind="captions" srclang="es" label="">
            </video>
            <p class="text-white text-opacity-50 text-center futurism pt-3">
              YEAR %
            </p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-11 col-md-9 py-5 px-md-5">
            <div v-if="props.leng === 'zh'" class="row">
              <div class="col-12 px-0 anime-tempoland">
                <h2 class="text-white text-opacity-75 futurism pt-3">
                  36.525 <span class="text-white text-opacity-50">天</span>
                </h2>
                <p class="text-white text-opacity-75 sofiaExtraLight pt-3 text-justify">
                  我们的时间已经到来。时间风暴给我们带来了充满可能性和令人期待的日子,
                  我们必须保存最重要的日期。每个 <span class="fw-bold">"Day NFTs"</span>
                  很快就会揭示它独特的性质和特征。
                </p>
              </div>
              <div class="col-12 px-0 pt-3 anime-tempoland">
                <h2 class="text-white text-opacity-75 futurism pt-3">
                  1.200 <span class="text-white text-opacity-50">个月</span>
                </h2>
                <p class="text-white text-opacity-75 sofiaExtraLight pt-3 text-justify">
                  对于那些希望对时间拥有更多控制权的人来说, Tempoland
                  包括这些特殊的高稀有性的 "月 <span class="fw-bold">NFTs"</span>。
                  其中这些NFT的智能合约允许持有者从更多的交易中获利。
                  拥有者将从该月的所有 <span class="fw-bold">"Day NFTs"</span> 的后续销售中获得 2% 的版税费用。
                </p>
              </div>
              <div class="col-12 px-0 pt-3 anime-tempoland">
                <h2 class="text-white text-opacity-75 futurism pt-3">
                  100 <span class="text-white text-opacity-50">年</span>
                </h2>
                <p class="text-white text-opacity-75 sofiaExtraLight pt-3 text-justify">
                  一小群持有者将赚取自己和他人时间的额外利润。 这些 <span class="fw-bold">"Year NFTs"</span>
                  是我们时间宇宙中最独特和最特殊的 <span class="fw-bold">NFT</span>。
                  每一年的NFT将提供所有在这一年里的<span class="fw-bold">"Day NFTs"</span>
                  的后续销售的 2% 的版税费用给Years <span class="fw-bold">NFT</span>的拥有者。
                </p>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-12 px-0 anime-tempoland">
                <h2 class="text-white text-opacity-75 futurism pt-3">
                  36.525 <span class="text-white text-opacity-50">Days</span>
                </h2>
                <p class="text-white text-opacity-75 sofiaExtraLight pt-3 text-justify">
                  Our time has come. The time storm brings us exciting days full of
                  possibilities, and we have to save <span class="fw-bold">the most
                  important dates.</span> Every <span class="fw-bold">‘Day NFT’</span>
                  will soon reveal <span class="fw-bold">its distinctive properties
                  and traits.</span>
                </p>
              </div>
              <div class="col-12 px-0 pt-3 anime-tempoland">
                <h2 class="text-white text-opacity-75 futurism pt-3">
                  1.200 <span class="text-white text-opacity-50">Months</span>
                </h2>
                <p class="text-white text-opacity-75 sofiaExtraLight pt-3 text-justify">
                  <span class="fw-bold">
                    For those who prefer to have more control over time, Tempoland includes
                    these special higher rarity 'month NFTs. The owner will get 2% of the
                    royalties from the successive sales of all 'Day NFTs' of their month.
                  </span>
                </p>
              </div>
              <div class="col-12 px-0 pt-3 anime-tempoland">
                <h2 class="text-white text-opacity-75 futurism pt-3">
                  100 <span class="text-white text-opacity-50">Years</span>
                </h2>
                <p class="text-white text-opacity-75 sofiaExtraLight pt-3 text-justify">
                  A select group of holders will make extra profit on their own time
                  <span class="fw-bold">and that of others.</span> These 'Year
                  NFTs ', the most exclusive and <span class="fw-bold">special in our
                  time universe, provide a 2% royalty on successive
                  sales of all 'Day NFTs' of the year.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    @mouseover="dAudio()"
    @focus="dAudio()"
    class="w-100 p-5"
  ></div>

  <div class="w-100 position-relative">
    <div class="row position-absolute h-100 w-100 m-0" style="z-index: 9;">
      <div class="col-12 p-0 h-100 marquesina">
      </div>
    </div>
    <div class="row position-static m-0">
      <div class="col-12 p-0">
        <div ref="sliderII" class="keen-slider">
          <div class="keen-slider__slide" style="min-width: 1140px; max-width: 1140px">
            <figure class="figure">
              <img src="../assets/images/participate_in_this_race_against_time.svg"
                class="figure-img img-fluid" alt="">
              <figcaption class="figure-caption"></figcaption>
            </figure>
          </div>
          <div class="keen-slider__slide" style="min-width: 1140px; max-width: 1140px">
            <figure class="figure">
              <img src="../assets/images/participate_in_this_race_against_time.svg"
                class="figure-img img-fluid" alt="">
              <figcaption class="figure-caption"></figcaption>
            </figure>
          </div>
          <div class="keen-slider__slide" style="min-width: 1140px; max-width: 1140px">
            <figure class="figure">
              <img src="../assets/images/participate_in_this_race_against_time.svg"
                class="figure-img img-fluid" alt="">
              <figcaption class="figure-caption"></figcaption>
            </figure>
          </div>
          <div class="keen-slider__slide" style="min-width: 1140px; max-width: 1140px">
            <figure class="figure">
              <img src="../assets/images/participate_in_this_race_against_time.svg"
                class="figure-img img-fluid" alt="">
              <figcaption class="figure-caption"></figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100 pt-5"></div>
  <div class="container p-5">
    <div class="card bg-transparent">
      <div class="card-body p-0">
        <div class="row justify-content-center box-nube">
          <div class="col-12 p-0 anime-tempoland">
            <video id="anime-tempoland" autoplay playsinline muted preload="metadata" loop
              poster="../assets/videos/mosaico/cloudsvoxel.png">
              <source src="../assets/videos/mosaico/cloudsvoxel.mp4" type="video/mp4">
              Su navegador no soporta contenido multimedia.
              <track src="" kind="captions" srclang="es" label="">
            </video>
          </div>
          <div class="col-11 col-md-9 p-5">
            <div class="row">
              <div class="col-12 px-0 anime-tempoland">
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 sofiaExtraLight text-justify
                  anime-titles-tempoland1"
                >
                  Tempoland 宇宙在一个特殊的大爆炸中诞生，爆炸中包括不同的时刻、事件、物品、人物和建筑。
                  我们每天都在继续制作新的草图，并花费大量时间建造新的“时间”
                </p>
                <p v-else
                  class="text-white text-opacity-75 sofiaExtraLight text-justify
                  anime-titles-tempoland1"
                >
                  Tempoland universe <span class="fw-bold">emerges</span> in a particular
                  Big-Bang of moments, events, objects, people and architectures. Day by
                  day we keep working on new sketches and investing a lot of time to build time.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-10 col-md-9 p-5">
            <div class="row">
              <div
                class="col-12 col-md-4 p-4 anime-tempoland"
                @mouseover="reproducir(2)"
                @focus="reproducir(2)"
                @mouseout="detener(2)"
                @blur="detener(2)"
              >
                <video id="video2" preload="metadata"
                  loop poster="../assets/videos/mosaico/logo.png">
                  <source src="../assets/videos/mosaico/logo.mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #522 冰时间物体
                </p>
                <p v-else
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #522 ice time object
                </p>
              </div>
              <div
                class="col-12 col-md-4 p-4 anime-tempoland"
                @mouseover="reproducir(3)"
                @focus="reproducir(3)"
                @mouseout="detener(3)"
                @blur="detener(3)"
              >
                <video id="video3" preload="metadata" loop
                  poster="../assets/videos/mosaico/m6_p1b.png">
                  <source src="../assets/videos/mosaico/m6_p1b.mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #256 白昼
                </p>
                <p v-else
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #256 daylight
                </p>
              </div>
              <div
                class="col-12 col-md-4 p-4 anime-tempoland"
                @mouseover="reproducir(4)"
                @focus="reproducir(4)"
                @mouseout="detener(4)"
                @blur="detener(4)"
              >
                <video id="video4" preload="metadata" loop
                  poster="../assets/videos/mosaico/mechanism.png">
                  <source src="../assets/videos/mosaico/mechanism.mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #525 机械
                </p>
                <p v-else
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #525 mechanism
                </p>
              </div>
            </div>

            <div
              class="row"
            >
              <div
                class="col-12 col-md-4 p-4 anime-tempoland"
                @mouseover="reproducir(5)"
                @focus="reproducir(5)"
                @mouseout="detener(5)"
                @blur="detener(5)"
              >
                <video id="video5" preload="metadata"
                  loop poster="../assets/videos/mosaico/bubble.png">
                  <source src="../assets/videos/mosaico/bubble.mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #32 气泡
                </p>
                <p v-else
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #32 bubble
                </p>
              </div>
              <div
                class="col-12 col-md-4 p-4 anime-tempoland"
                @mouseover="reproducir(6)"
                @focus="reproducir(6)"
                @mouseout="detener(6)"
                @blur="detener(6)"
              >
                <video id="video6" preload="metadata" loop
                  poster="../assets/videos/mosaico/f7_2.png">
                  <source src="../assets/videos/mosaico/f7_2.mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #11 烧毁云事件
                </p>
                <p v-else
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #11 clouds event
                </p>
              </div>
              <div
                class="col-12 col-md-4 p-4 anime-tempoland"
                @mouseover="reproducir(7)"
                @focus="reproducir(7)"
                @mouseout="detener(7)"
                @blur="detener(7)"
              >
                <video id="video7" preload="metadata" loop
                  poster="../assets/videos/mosaico/rayos.png">
                  <source src="../assets/videos/mosaico/rayos.mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #4 雷电物品
                </p>
                <p v-else
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #4 electric item
                </p>
              </div>
            </div>

            <div
              class="row"
            >
              <div
                class="col-12 col-md-4 p-4 anime-tempoland"
                @mouseover="reproducir(8)"
                @focus="reproducir(8)"
                @mouseout="detener(8)"
                @blur="detener(8)"
              >
                <video id="video8" preload="metadata" loop
                  poster="../assets/videos/mosaico/cala_picado.png">
                  <source src="../assets/videos/mosaico/cala_picado.mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #38 [∞] 移动建筑
                </p>
                <p v-else
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #38 [∞] mobile architecture
                </p>
              </div>
              <div
                class="col-12 col-md-4 p-4 anime-tempoland"
                @mouseover="reproducir(9)"
                @focus="reproducir(9)"
                @mouseout="detener(9)"
                @blur="detener(9)"
              >
                <video id="video9" preload="metadata" loop
                  poster="../assets/videos/mosaico/start.png">
                  <source src="../assets/videos/mosaico/start.mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #40 星星
                </p>
                <p v-else
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #40 star
                </p>
              </div>
              <div
                class="col-12 col-md-4 p-4 anime-tempoland"
                @mouseover="reproducir(10)"
                @focus="reproducir(10)"
                @mouseout="detener(10)"
                @blur="detener(10)"
              >
                <video id="video10" preload="metadata" loop
                  poster="../assets/videos/mosaico/arrival_event.png">
                  <source src="../assets/videos/mosaico/arrival_event.mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #25 到达事件
                </p>
                <p v-else
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #25 arrival event
                </p>
              </div>
            </div>

            <div
              class="row"
            >
              <div
                class="col-12 col-md-4 p-4 anime-tempoland"
                @mouseover="reproducir(11)"
                @focus="reproducir(11)"
                @mouseout="detener(11)"
                @blur="detener(11)"
              >
                <video id="video11" preload="metadata" loop
                  poster="../assets/videos/mosaico/chumo.png">
                  <source src="../assets/videos/mosaico/chumo.mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #227 雾时间物体
                </p>
                <p v-else
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #227 fog time object
                </p>
              </div>
              <div
                class="col-12 col-md-4 p-4 anime-tempoland"
                @mouseover="reproducir(12)"
                @focus="reproducir(12)"
                @mouseout="detener(12)"
                @blur="detener(12)"
              >
                <video id="video12" preload="metadata" loop
                  poster="../assets/videos/mosaico/arquitectura_horizontal.png">
                  <source src="../assets/videos/mosaico/arquitectura_horizontal.mp4"
                    type="video/mp4"
                  >
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #39 [∞] 移动建筑
                </p>
                <p v-else
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #39 [∞] mobile architecture
                </p>
              </div>
              <div
                class="col-12 col-md-4 p-4 anime-tempoland"
                @mouseover="reproducir(13)"
                @focus="reproducir(13)"
                @mouseout="detener(13)"
                @blur="detener(13)"
              >
                <video id="video13" preload="metadata" loop
                  poster="../assets/videos/mosaico/desplazamiento.png">
                  <source src="../assets/videos/mosaico/desplazamiento.mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #632 土地
                </p>
                <p v-else
                  class="text-white text-opacity-75 text-center sofiaExtraLight font-8">
                  #632 land
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 p-5"></div>
</template>

<script setup>
import {
  onBeforeMount,
  onMounted,
  // reactive,
  // ref,
  // watch,
} from 'vue';
import { useKeenSlider } from 'keen-slider/vue';

const props = defineProps({
  leng: {
    type: String,
  },
  muted: {
    type: Boolean,
  },
});

const animation = { duration: 90000, easing: (t) => t };

const [sliderI] = useKeenSlider({
  loop: true,
  mode: 'snap',
  renderMode: 'performance',
  drag: false,
  slides: { perView: 'auto' },
  created(s) {
    s.moveToIdx(5, true, animation);
  },
  updated(s) {
    s.moveToIdx(s.track.details.abs + 5, true, animation);
  },
  animationEnded(s) {
    s.moveToIdx(s.track.details.abs + 5, true, animation);
  },
});

const [sliderII] = useKeenSlider({
  loop: true,
  mode: 'snap',
  renderMode: 'performance',
  drag: false,
  slides: { perView: 'auto' },
  created(s) {
    s.moveToIdx(5, true, animation);
  },
  updated(s) {
    s.moveToIdx(s.track.details.abs + 5, true, animation);
  },
  animationEnded(s) {
    s.moveToIdx(s.track.details.abs + 5, true, animation);
  },
});

const reproducir = async (num) => {
  const play = document.getElementById(`video${num}`);
  if (play) {
    await play.play();
  }
};

const detener = async (num) => {
  const pause = document.getElementById(`video${num}`);
  if (pause) {
    await pause.pause();
    await pause.load();
  }
};

const rAudio = async () => {
  if (props.muted === false) {
    const tg8 = document.getElementById('audio-tg-8');
    const generico = document.getElementById('audio-generico-dias-tem');
    await tg8.pause();
    await generico.play();
    generico.muted = false;
  }
};

const dAudio = async () => {
  if (props.muted === false) {
    const tg8 = document.getElementById('audio-tg-8');
    const generico = document.getElementById('audio-generico-dias-tem');
    await generico.pause();
    await tg8.play();
    tg8.muted = false;
  }
};

onBeforeMount(async () => {
});

onMounted(async () => {
});
</script>

<style scoped lang="scss">
.border-warning {
  --bs-border-opacity: 0.6;
  --bs-warning-rgb: 255, 255, 0;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.linear-gradient80 {
  background: linear-gradient(180deg, rgba(66, 66, 66, 0.4) 80%, rgba(0, 0, 0, 1) 100%);
}
</style>
