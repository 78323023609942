<template>
  <div class="container p-5 mt-5">
    <div class="card bg-black pt-5">
      <div class="card-body p-0">
        <div id="chain-events" class="row justify-content-center">
          <div class="col-11 col-md-9 px-md-5">
            <div class="row justify-content-center align-items-center">
              <div v-if="props.leng === 'zh'" class="col-12 px-0">
                <h2 class="text-primary pb-5 futurism anime-tempoland">
                  事件链
                </h2>
                <p class="text-white text-opacity-75 pt-4 sofiaExtraLight text-justify
                  anime-tempoland"
                >
                  Croonos研究时间的表示和概念化。我们致力于动画、生活和生成日历以及其他虚拟世界的4D工具的开发。
                </p>
                <p class="text-white text-opacity-75 pt-4 sofiaExtraLight text-justify
                  anime-tempoland"
                >
                  每个对象都被设计为一个事件链的链环，随着社区的增长，它们将固定在区块链上。
                </p>
                <p class="text-white text-opacity-75 pt-4 sofiaExtraLight text-justify
                  anime-tempoland"
                >
                  路线图是一个开放的时间线，涵盖了多个物理和数字收藏以及与领先艺术家和品牌的合作。
                </p>
              </div>
              <div v-else class="col-12 px-0">
                <h2 class="text-primary pb-5 futurism anime-tempoland">
                  CHAIN OF EVENTS
                </h2>
                <p class="text-white text-opacity-75 pt-4 sofiaExtraLight text-justify
                  anime-tempoland"
                >
                  Croonos researches the representation and conceptualization of time.
                  We work on the development of animated, live and generative calendars,
                  as well as other 4D tools for virtual worlds.
                </p>
                <p class="text-white text-opacity-75 pt-4 sofiaExtraLight text-justify
                  anime-tempoland"
                >
                  Every object is designed as a link of chain of timed events
                  that will be blockchained as the community grows.
                </p>
                <p class="text-white text-opacity-75 pt-4 sofiaExtraLight text-justify
                  anime-tempoland"
                >
                  Roadmap is an open <span class="fw-bold">timeline</span> in the development of
                  several collections and objects, both physical and digital, as well as
                  collaborations with leading artists and brands.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid px-0">
    <div class="card bg-black pt-5">
      <div class="card-body p-0">
        <div class="accordion">
          <div class="accordion-item">
            <div class="accordion-header">
              <div class="row border-top border-bottom border-secondary">
                <div class="col-7 offset-3 ps-0">
                  <button
                    @click="animateProgress(80, '#progress-80')"
                    class="accordion-button collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="false"
                  >
                    <h4 v-if="props.leng === 'zh'" class="text-white text-opacity-75 futurism m-0">
                      Tempoland
                    </h4>
                    <h4 v-else class="text-white text-opacity-75 futurism m-0">
                      Tempoland
                    </h4>
                  </button>
                </div>
              </div>
            </div>
            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse">
              <div class="accordion-body p-0">
                <div class="row">
                  <div id="progress-80" class="bg-primary p-0" style="height: 15px; width: 1%">
                  </div>
                  <div class="col-auto ps-3 p-0">
                    <p class="text-white text-opacity-75 m-0 sofiaExtraLight align-top font-8">
                      80%
                    </p>
                  </div>
                </div>
                <div class="row py-5">
                  <div v-if="props.leng === 'zh'" class="col-6 offset-3 p-0">
                   <p class="text-white text-opacity-75 sofiaExtraLight">
                      在Tempoland日历中，每一天都是一个独立的立方体形状，但实际上它是一个没有限制的空间。
                      一天可以容纳许多事件，如果一个接一个地排列，将持续好几辈子。每个4D立方体充满了可能性，
                      我们将一起决定它们的命运。
                   </p>
                  </div>
                  <div v-else class="col-6 offset-3 p-0">
                   <p class="text-white text-opacity-75 sofiaExtraLight">
                      In Tempoland  calendar each day is shaped as a cube, but it is actually a
                      space that has no limits. A single day can host many events that if sequenced
                      one after another would last many lifetimes. Each 4D cube is full of
                      possibilities and we will all decide them together.
                   </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header">
              <div class="row border-top border-bottom border-secondary">
                <div class="col-7 offset-3 ps-0">
                  <button
                    @click="animateProgress(50, '#progress-50')"
                    class="accordion-button collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="false"
                  >
                    <h4 v-if="props.leng === 'zh'" class="text-white text-opacity-75 futurism m-0">
                      时空生物
                    </h4>
                    <h4 v-else class="text-white text-opacity-75 futurism m-0">
                      Time Creatures
                    </h4>
                  </button>
                </div>
              </div>
            </div>
            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingTwo">
              <div class="accordion-body p-0">
                <div class="row">
                  <div id="progress-50" class="bg-primary p-0" style="height: 15px; width: 1%">
                  </div>
                  <div class="col-auto ps-3 p-0">
                    <p class="text-white text-opacity-75 m-0 sofiaExtraLight align-top font-8">
                      50%
                    </p>
                  </div>
                </div>
                <div class="row py-5">
                  <div v-if="props.leng === 'zh'" class="col-6 offset-3 p-0">
                    <h5 class="text-white text-opacity-75 pt-4 sofiaExtraLight">TEMPOGENESIS</h5>
                    <p class="text-white text-opacity-75 pt-4 sofiaExtraLight">
                       这个是即将诞生的Collection的预览。我们很快将揭示出第一批
                       <span class="fw-bold">4D</span>时间生物。
                    </p>

                    <h5 class="text-white text-opacity-75 pt-4 sofiaExtraLight">TEMPO</h5>
                    <p class="text-white text-opacity-75 pt-4 sofiaExtraLight">
                      未来的时间守护者现在还处于<span class="fw-bold">2D</span>阶段，它们正在不断地进化来定义它们的本质、
                      力量，并采取其最终形态。发布时间将按时宣布。
                    </p>
                    <p class="text-white text-opacity-75 sofiaExtraLight">
                       Tempo将成为首个生活在这个宇宙中的时间生物。掌握所有事件，Tempo将成为日期的守护者和我们的数字身份，
                       充当指挥官，与社区其他成员互动，并在Tempoland地理日历中旅行。
                    </p>
                  </div>
                  <div v-else class="col-6 offset-3 p-0">
                    <h5 class="text-white text-opacity-75 pt-4 sofiaExtraLight">TEMPOGENESIS</h5>
                   <p class="text-white text-opacity-75 pt-4 sofiaExtraLight">
                      This collection of births is a foretaste of what is to come.
                    <span class="fw-bold">Soon the first 4D time creatures will
                      be revealed.</span>
                   </p>

                    <h5 class="text-white text-opacity-75 pt-4 sofiaExtraLight">TEMPO</h5>
                   <p class="text-white text-opacity-75 pt-4 sofiaExtraLight">
                      The future time <span class="fw-bold">keepers</span> are now at a 2D
                      stage, continually evolving to define their nature, their
                      powers and to adopt <span class="fw-bold">its</span> definitive shape.
                      Launching will be punctually announced.
                   </p>
                   <p class="text-white text-opacity-75 sofiaExtraLight">
                      Tempo will emerge as the first creature of time that will live in this
                      universe. Mastering all events, <span class="fw-bold">Tempo will be
                      guardian</span> of the dates and our digital identity to act as
                      conductor, interact with other members of the community <span class="fw-bold">
                      and</span> travel in Tempoland geocalendar.
                   </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header">
              <div class="row border-top border-bottom border-secondary">
                <div class="col-7 offset-3 ps-0">
                  <button
                    @click="animateProgress(45, '#progress-45')"
                    class="accordion-button collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseThree"
                    aria-expanded="false"
                  >
                    <h4 v-if="props.leng === 'zh'" class="text-white text-opacity-75 futurism m-0">
                      时间物品
                    </h4>
                    <h4 v-else class="text-white text-opacity-75 futurism m-0">
                      Time Items
                    </h4>
                  </button>
                </div>
              </div>
            </div>
            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body p-0">
                <div class="row">
                  <div id="progress-45" class="bg-primary p-0" style="height: 15px; width: 1%">
                  </div>
                  <div class="col-auto ps-3 p-0">
                    <p class="text-white text-opacity-75 m-0 sofiaExtraLight align-top font-8">
                      45%
                    </p>
                  </div>
                </div>
                <div class="row py-5">
                  <div v-if="props.leng === 'zh'" class="col-6 offset-3 p-0">
                   <p class="text-white text-opacity-75 sofiaExtraLight">
                      时间的表示将化为几种物理和数字物品，它们将充当解锁空间-时间或让您访问独家事件的钥匙。
                   </p>
                  </div>
                  <div v-else class="col-6 offset-3 p-0">
                   <p class="text-white text-opacity-75 sofiaExtraLight">
                      Time representation <span class="fw-bold">will materialize</span> into several
                      physical and digital items that will work as keys to unlock your space-time or
                      give you access to exclusive events.
                   </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <div class="accordion-header">
              <div class="row border-top border-bottom border-secondary">
                <div class="col-7 offset-3 ps-0">
                  <button
                    @click="animateProgress(40, '#progress-40')"
                    class="accordion-button collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFor"
                    aria-expanded="false"
                  >
                    <h4 v-if="props.leng === 'zh'" class="text-white text-opacity-75 futurism m-0">
                      生态系统
                    </h4>
                    <h4 v-else class="text-white text-opacity-75 futurism m-0">
                      Ecosystem
                    </h4>
                  </button>
                </div>
              </div>
            </div>
            <div id="panelsStayOpen-collapseFor" class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body p-0">
                <div class="row">
                  <div id="progress-40" class="bg-primary p-0" style="height: 15px; width: 10%">
                  </div>
                  <div class="col-auto ps-3 p-0">
                    <p class="text-white text-opacity-75 m-0 sofiaExtraLight align-top font-8">
                      40%
                    </p>
                  </div>
                </div>
                <div class="row py-5">
                  <div v-if="props.leng === 'zh'" class="col-6 offset-3 p-0">
                   <p class="text-white text-opacity-75 sofiaExtraLight">
                      在未来的Tempoland宇宙中，社区将在我们共同建造的新世界中互动、享受和花费时间。
                      这个空间-时间的实际维度将由其使用、目的、动机或其所包含的事件来确定。
                   </p>
                  </div>
                  <div v-else class="col-6 offset-3 p-0">
                   <p class="text-white text-opacity-75 sofiaExtraLight">
                      In future Tempoland universe, the community is to interact, enjoy and spend
                      their time in the new world we are building together. The actual dimensions
                      of this space-time will be set <span class="fw-bold">by its use, purposes,
                      </span>motivations or the events that <span class="fw-bold">it would contain.
                      </span>
                   </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <div class="accordion-header">
              <div class="row border-top border-bottom border-secondary">
                <div class="col-7 offset-3 ps-0">
                  <button
                    @click="animateProgress(35, '#progress-35')"
                    class="accordion-button collapsed ps-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseFive"
                    aria-expanded="false"
                  >
                    <h4 v-if="props.leng === 'zh'" class="text-white text-opacity-75 futurism m-0">
                      山丘之王
                    </h4>
                    <h4 v-else class="text-white text-opacity-75 futurism m-0">
                      King Of The Hill
                    </h4>
                  </button>
                </div>
              </div>
            </div>
            <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body p-0">
                <div class="row">
                  <div id="progress-35" class="bg-primary p-0" style="height: 15px; width: 1%">
                  </div>
                  <div class="col-auto ps-3 p-0">
                    <p class="text-white text-opacity-75 m-0 sofiaExtraLight align-top font-8">
                      35%
                    </p>
                  </div>
                </div>
                <div class="row py-5">
                  <div v-if="props.leng === 'zh'" class="col-6 offset-3 p-0">
                   <p class="text-white text-opacity-75 sofiaExtraLight">
                      即将推出
                   </p>
                  </div>
                  <div v-else class="col-6 offset-3 p-0">
                   <p class="text-white text-opacity-75 sofiaExtraLight">
                      Coming soon.
                   </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card bg-black">
      <div class="card-body p-0">
        <div class="row mt-5 pt-5">
          <div class="col-12 pt-5 text-center">
            <h6 v-if="props.leng === 'zh'"
              class="text-white text-opacity-75 futurism anime-tempoland">
              我们将一起走这条道路!
            </h6>
            <h6 v-else
              class="text-white text-opacity-75 futurism anime-tempoland">
              WE WILL WALK THE ROAD TOGETHER
            </h6>
          </div>
          <div class="col-12 p-0">
            <video autoplay playsinline muted preload="metadata" loop
              poster="../assets/videos/canon_wire.png">
              <source src="../assets/videos/canon_wire.mp4" type="video/mp4">
              Su navegador no soporta contenido multimedia.
              <track src="" kind="captions" srclang="es" label="">
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  onBeforeMount,
  onMounted,
  // reactive,
  // computed,
} from 'vue';
import { gsap } from 'gsap';

const props = defineProps({
  leng: {
    type: String,
  },
});

const animateProgress = (tam, tag) => {
  const activate = gsap.timeline({ paused: true })
    .to(
      tag,
      {
        width: `${tam}%`,
        height: '15px',
        x: 0,
        y: 0,
        duration: 5,
        opacity: 1,
        repeat: 0,
        rotation: 0,
        autoRound: false,
        transformOrigin: `0% ${tam}%`,
      },
    );
  activate.restart();
  activate.play();
};

onBeforeMount(async () => {
});

onMounted(async () => {
});
</script>

<style scoped lang="scss">
.bg-warning {
  --bs-bg-opacity: 0.5 !important;
  --bs-warning-rgb: 255, 255, 0;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}
</style>
