<template>
  <audio id="audio-tg-8" playsinline muted loop>
    <source src="../assets/audio/bucleReloj.ogg" type="audio/ogg">
    <source src="../assets/audio/bucleReloj.wav" type="audio/wav">
    Your browser does not support the <code>audio</code> element.
    <track kind="captions" />
  </audio>
  <audio id="audio-generico-dias-tem" playsinline muted loop>
    <source src="../assets/audio/genericoDias.ogg" type="audio/ogg">
    <source src="../assets/audio/genericoDias.wav" type="audio/wav">
    Your browser does not support the <code>audio</code> element.
    <track kind="captions" />
  </audio>
  <div
    id="sliding-machine"
    class="row align-items-center position-absolute w-100 m-0"
    style="z-index: 100; top: 45%; pointer-events: none;"
  >
    <div class="col-9 p-0 p-t-4">
      <p class="text-white text-opacity-50 futurism font-8 ps-5 m-0">
        {{ datos.hour.toString().padStart(2) }}
        :{{ datos.min.toString().padStart(2) }}
        :{{ datos.sec.toString().padStart(2) }}
      </p>
      <div class="w-100 p-1 linea-sliding-machine"></div>
    </div>
    <div class="col-3 p-0">
      <figure class="figure m-0" style="min-width:100%;">
        <img src="../assets/images/sliding_machine.png" class="figure-img img-fluid m-0" alt=""
          style="min-width:100%;"
        >
        <figcaption class="figure-caption"></figcaption>
      </figure>
    </div>
  </div>
  <div
    v-if="false"
    @mousemove="seguiraudio()"
    id="seg-generico-dias"
    class="container-fluid bg-black bg-opacity-10 p-0 position-fixed"
    style="min-height: 100vh; min-width: 100wh; overflow-x: hidden; overflow-y: hidden;"
  >
    <div v-if="datos.genericoDias" id="play-generico-dias">
      <button @click="genericoDias()" type="button" class="btn">
        <BootstrapIcon class="m-0" icon="play-circle" size="3x" variant="light" flip-v />
      </button>
    </div>
  </div>
  <NavBarSection :leng="datos.leng" />
  <div
    data-bs-spy="scroll"
    data-bs-root-margin="0px 0px -40%"
    data-bs-smooth-scroll="true"
    class="p-0"
    tabindex="0"
  >
  </div>
  <div class="position-fixed pt-5" style="z-index: 101; top: 70%">
    <div class="row justify-content-end ps-5">
      <div class="col-auto">
        <button @click="bucleReloj('muted')" type="button" class="btn">
          <BootstrapIcon icon="soundwave" size="3x" variant="secondary"
            animation="throb" />
        </button>
      </div>
    </div>
  </div>
  <HomeSection :leng="datos.leng" :muted="datos.muted"/>
  <TempoLandSection :leng="datos.leng" :muted="datos.muted"/>
  <TimeCreaturesSection :leng="datos.leng"/>
  <ChainOfEventsSection :leng="datos.leng"/>
  <FooterSection :leng="datos.leng"/>
  <Footer2Section :leng="datos.leng"/>
</template>

<script setup>
import { onBeforeMount, onMounted, reactive } from 'vue';
import { useRoute } from 'vue-router';
import NavBarSection from '@/components/NavBarSection.vue';
import HomeSection from '@/components/HomeSection.vue';
import TempoLandSection from '@/components/TempoLandSection.vue';
import TimeCreaturesSection from '@/components/TimeCreaturesSection.vue';
import ChainOfEventsSection from '@/components/ChainOfEventsSection.vue';
import FooterSection from '@/components/FooterSection.vue';
import Footer2Section from '@/components/Footer2Section.vue';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';

gsap.registerPlugin(ScrollTrigger, TextPlugin);
const route = useRoute();

const datos = reactive({
  sec: 0,
  min: 0,
  hour: 0,
  timer: null,
  segGenericoDias: true,
  genericoDias: true,
  leng: '',
  muted: false,
});

const leng = async () => {
  if (route.params.leng === 'zh') {
    datos.leng = 'zh';
  }
};

const bucleReloj = async (action) => {
  const tg8 = document.getElementById('audio-tg-8');
  const generico = document.getElementById('audio-generico-dias-tem');
  if (action === 'play') {
    tg8.play();
  }
  if (action === 'pause') {
    tg8.pause();
  }
  if (action === 'muted') {
    if (datos.muted === true) {
      tg8.muted = false;
      generico.muted = false;
      datos.muted = false;
    } else {
      tg8.muted = true;
      generico.muted = true;
      datos.muted = true;
    }
  }
};

const seguiraudio = async () => {
  if (datos.genericoDias) {
    const generico = document.getElementById('play-generico-dias');
    const pageY = parseInt(window.event.pageY, 10) - 30;
    const pageX = parseInt(window.event.pageX, 10) - 35;
    generico.style.top = `${pageY}px`;
    generico.style.left = `${pageX}px`;
  }
};

const genericoDias = async () => {
  datos.genericoDias = false;
  datos.segGenericoDias = false;
  const playGenerico = document.getElementById('audio-generico-dias');
  playGenerico.play();
};

const animacionesTempoland = async () => {
  const tempoland = gsap.utils.toArray('.anime-tempoland');
  tempoland.forEach((tl) => {
    const anim = gsap.from(tl, { opacity: 0, y: 100, duration: 1 });
    ScrollTrigger.create({
      trigger: tl,
      animation: anim,
      toggleActions: 'restart complete none none',
      once: true,
    });
  });
};

const slidingMachine = async () => {
  let divHeight;
  const obj = document.getElementById('app');

  if (obj.offsetHeight) {
    divHeight = obj.offsetHeight;
  } else if (obj.style.pixelHeight) {
    divHeight = obj.style.pixelHeight;
  }

  const machine = document.getElementById('sliding-machine');
  const createTimeline = (element) => {
    const tl = gsap.timeline({
      repeat: -1,
      repeatDelay: 1,
    });

    /* tl.fromTo(
      element,
      {
        yPercent: 50,
      },
      {
        yPercent: 100,
        top: (divHeight * 2) - (divHeight / 5),
        duration: 20,
        ease: 'none',
      },
    ); */
    tl.to(
      element,
      divHeight,
      {
        yPercent: 100,
        top: (divHeight * 2) - (divHeight / 5),
        force3D: true,
      },
    );
    tl.totalTime(5);
    return tl;
  };

  createTimeline(machine);

  /* const child1Timeline = createTimeline(machine);
  const child1Time = document.getElementById('child1Time');
  let timeScale = 1;
  const applyTimeScale = () => {
    child1Timeline.timeScale(timeScale);
  };

  const reverse = () => {
    timeScale *= -1;
    applyTimeScale();
  };
  const toggleSpeed = () => {
    if (Math.abs(timeScale) === 1) {
      timeScale *= 2;
    } else {
      timeScale /= 2;
      applyTimeScale();
    }
  };
  const displayTimes = () => {
    child1Time.innerText = child1Timeline.time();
    requestAnimationFrame(displayTimes);
  };
  requestAnimationFrame(displayTimes); */
};

const playing = async () => {
  datos.sec += 1;
  if (datos.sec >= 59) {
    datos.sec = 0;
    datos.min += 1;
  }
  if (datos.min >= 59) {
    datos.min = 0;
    datos.hour += 1;
  }
};

const playCronoetro = () => {
  if (datos.timer === null) {
    playing();
    datos.timer = setInterval(() => playing(), 1000);
  } else {
    clearInterval(datos.timer);
    datos.timer = null;
    // pause();
  }
};

onBeforeMount(() => { });

onMounted(async () => {
  await leng();
  await animacionesTempoland();
  await slidingMachine();
  await playCronoetro();
});
</script>

<style scoped lang="scss">
#play-generico-dias {
  position: absolute;
  height: 4rem;
  width: 4rem;
  background: rgba(255, 255, 255, 0);
  border-radius: 15rem;
  top: 50%;
  left: 50%;
  padding: 2px 0 0 0;
}

.linea-sliding-machine {
  background: repeat-x url("../assets/images/linea_sliding_machine.svg");
}
</style>
