<template>
  <NavBar :leng="datos.leng" />

  <div class="container-fluid pt-5 p-0">
    <div class="card bg-black">
      <div class="card-body position-relative">
        <div class="row justify-content-center pt-5">
          <div class="col-10 col-md-5">
            <div class="row py-5">
              <div
                @mouseover="logoTeam(true)"
                @focus="logoTeam(true)"
                @mouseout="logoTeam(false)"
                @blur="logoTeam(false)"
                class="col-auto"
              >
                <figure role="button" class="figure">
                  <img v-if="datos.faqlogo" src="../assets/images/logo_180.gif"
                    class="figure-img img-fluid logo-2" alt="">
                  <img v-else src="../assets/images/logo.gif"
                    class="figure-img img-fluid logo-2" alt="">
                  <figcaption class="figure-caption"></figcaption>
                </figure>
              </div>
              <div class="col-auto">
                <h1 v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 futurism">
                  团队
                </h1>
                <h1 v-else
                  class="text-white text-opacity-75 futurism">
                  TEAM
                </h1>
              </div>
              <div class="w-100"></div>
              <div class="col-12 py-5">
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 text-justify sofiaExtraLight">
                  <span class="fw-bold">Chroonos Lab</span>
                  是一支具有多元化经验的团队，他们拥有艺术、技术、商业、房地产、
                  传播和市场营销等领域的经验，共同致力于一个共同的使命：创造虚拟世界和<span class="fw-bold">NFT</span>的收藏，
                  不仅促进<span class="fw-bold">Web3</span>的发展，而且为去中心化未来开辟了新的应用。
                </p>
                <p v-else
                  class="text-white text-opacity-75 text-justify sofiaExtraLight">
                  <span class="fw-bold">Croonos</span> Lab is a heterogeneous team with experience
                  in different areas of art, technology, business, real state, communication and
                  marketing, working together on a common mission: the creation of virtual worlds
                  and collections of NFT´s that not only make the web3 grow but also open the
                  door to new utilities in a decentralized future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid p-0">
    <div class="navigation-wrapper">
      <div ref="container" class="keen-slider">
        <div class="keen-slider__slide number-slide1 px-3">
          <!-- <div class="row row-cols-10">
            <div
              @mouseover="fotosTeam(fotos.JAVI = false)"
              @focus="fotosTeam(fotos.JAVI = false)"
              @mouseout="fotosTeam(fotos.JAVI = true)"
              @blur="fotosTeam(fotos.JAVI = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.JAVI" src="../assets/images/team/blanco/01JAVI_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/01JAVI_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
                  <figure role="button" class="figure m-0">
                    <img src="../assets/images/team/foto_transparent.svg"
                      class="figure-img img-fluid m-0" alt="">
                    <figcaption class="figure-caption"></figcaption>
                  </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.NURIA = false)"
              @focus="fotosTeam(fotos.NURIA = false)"
              @mouseout="fotosTeam(fotos.NURIA = true)"
              @blur="fotosTeam(fotos.NURIA = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.NURIA" src="../assets/images/team/blanco/03NURIA_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/03NURIA_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.DANI = false)"
              @focus="fotosTeam(fotos.DANI = false)"
              @mouseout="fotosTeam(fotos.DANI = true)"
              @blur="fotosTeam(fotos.DANI = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.DANI" src="../assets/images/team/blanco/05DANI_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/05DANI_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.JUANDIEGO = false)"
              @focus="fotosTeam(fotos.JUANDIEGO = false)"
              @mouseout="fotosTeam(fotos.JUANDIEGO = true)"
              @blur="fotosTeam(fotos.JUANDIEGO = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.JUANDIEGO"
                  src="../assets/images/team/blanco/07JUANDIEGO_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/07JUANDIEGO_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.AMAIA = false)"
              @focus="fotosTeam(fotos.AMAIA = false)"
              @mouseout="fotosTeam(fotos.AMAIA = true)"
              @blur="fotosTeam(fotos.AMAIA = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.AMAIA"
                  src="../assets/images/team/blanco/09AMAIA_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/09AMAIA_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
          </div>
          <div class="row row-cols-10">
            <div
              id="javi"
              @mouseover="textTeam('JAVI', '#javi')"
              @focus="textTeam('JAVI', '#javi')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/JAVI_text.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.RAQUEL = false)"
              @focus="fotosTeam(fotos.RAQUEL = false)"
              @mouseout="fotosTeam(fotos.RAQUEL = true)"
              @blur="fotosTeam(fotos.RAQUEL = true)"
              class="col p-0"
              >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.RAQUEL" src="../assets/images/team/blanco/02RAQUEL_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/02RAQUEL_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              id="raquel"
              @mouseover="textTeam('RAQUEL', '#raquel')"
              @focus="textTeam('RAQUEL', '#raquel')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/RAQUEL_text.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.JOSECHU = false)"
              @focus="fotosTeam(fotos.JOSECHU = false)"
              @mouseout="fotosTeam(fotos.JOSECHU = true)"
              @blur="fotosTeam(fotos.JOSECHU = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.JOSECHU"
                  src="../assets/images/team/blanco/04JOSECHU_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/04JOSECHU_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              id="nuria"
              @mouseover="textTeam('NURIA', '#nuria')"
              @focus="textTeam('NURIA', 'nuria')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/NURIA_text.svg"
                  class="figure-img img-fluid m-0" alt="">
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.CHRISTIAN = false)"
              @focus="fotosTeam(fotos.CHRISTIAN = false)"
              @mouseout="fotosTeam(fotos.CHRISTIAN = true)"
              @blur="fotosTeam(fotos.CHRISTIAN = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.CHRISTIAN"
                  src="../assets/images/team/blanco/06CHRISTIAN_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/06CHRISTIAN_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              id="josechu"
              @mouseover="textTeam('JOSECHU', '#josechu')"
              @focus="textTeam('JOSECHU', '#josechu')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/JOSECHU_text.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.CARLOSHURTADO = false)"
              @focus="fotosTeam(fotos.CARLOSHURTADO = false)"
              @mouseout="fotosTeam(fotos.CARLOSHURTADO = true)"
              @blur="fotosTeam(fotos.CARLOSHURTADO = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.CARLOSHURTADO"
                  src="../assets/images/team/blanco/08CARLOSHURTADO_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/08CARLOSHURTADO_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              id="dani"
              @mouseover="textTeam('DANI', '#dani')"
              @focus="textTeam('DANI', '#dani')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/DANI_text.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.SONIA = false)"
              @focus="fotosTeam(fotos.SONIA = false)"
              @mouseout="fotosTeam(fotos.SONIA = true)"
              @blur="fotosTeam(fotos.SONIA = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.SONIA"
                  src="../assets/images/team/blanco/10SONIA_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/10SONIA_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
          </div> -->

          <div class="row row-cols-10">
            <div
              @mouseover="fotosTeam(fotos.JAVI = false)"
              @focus="fotosTeam(fotos.JAVI = false)"
              @mouseout="fotosTeam(fotos.JAVI = true)"
              @blur="fotosTeam(fotos.JAVI = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.JAVI" src="../assets/images/team/blanco/01JAVI_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/01JAVI_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.RAQUEL = false)"
              @focus="fotosTeam(fotos.RAQUEL = false)"
              @mouseout="fotosTeam(fotos.RAQUEL = true)"
              @blur="fotosTeam(fotos.RAQUEL = true)"
              class="col p-0"
              >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.RAQUEL" src="../assets/images/team/blanco/02RAQUEL_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/02RAQUEL_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.NURIA = false)"
              @focus="fotosTeam(fotos.NURIA = false)"
              @mouseout="fotosTeam(fotos.NURIA = true)"
              @blur="fotosTeam(fotos.NURIA = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.NURIA" src="../assets/images/team/blanco/03NURIA_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/03NURIA_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.JOSECHU = false)"
              @focus="fotosTeam(fotos.JOSECHU = false)"
              @mouseout="fotosTeam(fotos.JOSECHU = true)"
              @blur="fotosTeam(fotos.JOSECHU = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.JOSECHU"
                  src="../assets/images/team/blanco/04JOSECHU_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/04JOSECHU_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.DANI = false)"
              @focus="fotosTeam(fotos.DANI = false)"
              @mouseout="fotosTeam(fotos.DANI = true)"
              @blur="fotosTeam(fotos.DANI = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.DANI" src="../assets/images/team/blanco/05DANI_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/05DANI_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
          </div>
          <div class="row row-cols-10">
            <div
              id="javi"
              @mouseover="textTeam('JAVI', '#javi')"
              @focus="textTeam('JAVI', '#javi')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/JAVI_text.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.CHRISTIAN = false)"
              @focus="fotosTeam(fotos.CHRISTIAN = false)"
              @mouseout="fotosTeam(fotos.CHRISTIAN = true)"
              @blur="fotosTeam(fotos.CHRISTIAN = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.CHRISTIAN"
                  src="../assets/images/team/blanco/06CHRISTIAN_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/06CHRISTIAN_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              id="raquel"
              @mouseover="textTeam('RAQUEL', '#raquel')"
              @focus="textTeam('RAQUEL', '#raquel')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/RAQUEL_text.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.JUANDIEGO = false)"
              @focus="fotosTeam(fotos.JUANDIEGO = false)"
              @mouseout="fotosTeam(fotos.JUANDIEGO = true)"
              @blur="fotosTeam(fotos.JUANDIEGO = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.JUANDIEGO"
                  src="../assets/images/team/blanco/07JUANDIEGO_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/07JUANDIEGO_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              id="nuria"
              @mouseover="textTeam('NURIA', '#nuria')"
              @focus="textTeam('NURIA', 'nuria')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/NURIA_text.svg"
                  class="figure-img img-fluid m-0" alt="">
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.CARLOSHURTADO = false)"
              @focus="fotosTeam(fotos.CARLOSHURTADO = false)"
              @mouseout="fotosTeam(fotos.CARLOSHURTADO = true)"
              @blur="fotosTeam(fotos.CARLOSHURTADO = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.CARLOSHURTADO"
                  src="../assets/images/team/blanco/08CARLOSHURTADO_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/08CARLOSHURTADO_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              id="josechu"
              @mouseover="textTeam('JOSECHU', '#josechu')"
              @focus="textTeam('JOSECHU', '#josechu')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/JOSECHU_text.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.AMAIA = false)"
              @focus="fotosTeam(fotos.AMAIA = false)"
              @mouseout="fotosTeam(fotos.AMAIA = true)"
              @blur="fotosTeam(fotos.AMAIA = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.AMAIA"
                  src="../assets/images/team/blanco/09AMAIA_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/09AMAIA_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              id="dani"
              @mouseover="textTeam('DANI', '#dani')"
              @focus="textTeam('DANI', '#dani')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/DANI_text.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.SONIA = false)"
              @focus="fotosTeam(fotos.SONIA = false)"
              @mouseout="fotosTeam(fotos.SONIA = true)"
              @blur="fotosTeam(fotos.SONIA = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.SONIA"
                  src="../assets/images/team/blanco/10SONIA_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/10SONIA_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
          </div>
          <div class="row row-cols-10">
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              id="christian"
              @mouseover="textTeam('CHRISTIAN', '#christian')"
              @focus="textTeam('CHRISTIAN', '#christian')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/CHRISTIAN_text.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              id="juandiego"
              @mouseover="textTeam('JUANDIEGO', '#juandiego')"
              @focus="textTeam('JUANDIEGO', '#juandiego')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/JUANDIEGO_text.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              id="carloshurtado"
              @mouseover="textTeam('CARLOSHURTADO', '#carloshurtado')"
              @focus="textTeam('CARLOSHURTADO', '#carloshurtado')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/CARLOSHURTADO_text.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              id="amaia"
              @mouseover="textTeam('AMAIA', '#amaia')"
              @focus="textTeam('AMAIA', '#amaia')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/AMAIA_text.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              id="sonia"
              @mouseover="textTeam('SONIA', '#sonia')"
              @focus="textTeam('SONIA', '#sonia')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/SONIA_text.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
          </div>
        </div>
        <div class="keen-slider__slide number-slide2 px-3">
          <div class="row row-cols-10">
            <div
              @mouseover="fotosTeam(fotos.JESUS = false)"
              @focus="fotosTeam(fotos.JESUS = false)"
              @mouseout="fotosTeam(fotos.JESUS = true)"
              @blur="fotosTeam(fotos.JESUS = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.JESUS" src="../assets/images/team/blanco/11JESUS_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/11JESUS_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
                  <figure role="button" class="figure m-0">
                    <img src="../assets/images/team/foto_transparent.svg"
                      class="figure-img img-fluid m-0" alt="">
                    <figcaption class="figure-caption"></figcaption>
                  </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.MARIANA = false)"
              @focus="fotosTeam(fotos.MARIANA = false)"
              @mouseout="fotosTeam(fotos.MARIANA = true)"
              @blur="fotosTeam(fotos.MARIANA = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.MARIANA"
                  src="../assets/images/team/blanco/13MARIANA_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/13MARIANA_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
                  <figure role="button" class="figure m-0">
                    <img src="../assets/images/team/foto_transparent.svg"
                      class="figure-img img-fluid m-0" alt="">
                    <figcaption class="figure-caption"></figcaption>
                  </figure>
            </div>
            <div class="col p-0">
                  <figure role="button" class="figure m-0">
                    <img src="../assets/images/team/foto_transparent.svg"
                      class="figure-img img-fluid m-0" alt="">
                    <figcaption class="figure-caption"></figcaption>
                  </figure>
            </div>
            <div class="col p-0">
                  <figure role="button" class="figure m-0">
                    <img src="../assets/images/team/foto_transparent.svg"
                      class="figure-img img-fluid m-0" alt="">
                    <figcaption class="figure-caption"></figcaption>
                  </figure>
            </div>
            <div
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
          </div>
          <div class="row row-cols-10">
            <div
              id="jesus"
              @mouseover="textTeam('JESUS', '#jesus')"
              @focus="textTeam('JESUS', '#jesus')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/JESUS_text.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              @mouseover="fotosTeam(fotos.PABLO = false)"
              @focus="fotosTeam(fotos.PABLO = false)"
              @mouseout="fotosTeam(fotos.PABLO = true)"
              @blur="fotosTeam(fotos.PABLO = true)"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img v-if="fotos.PABLO" src="../assets/images/team/blanco/12PABLO_blanco.png"
                  class="figure-img img-fluid m-0" alt="">
                <img v-else src="../assets/images/team/fotos/12PABLO_foto.png"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              id="mariana"
              @mouseover="textTeam('MARIANA', '#mariana')"
              @focus="textTeam('MARIANA', 'mariana')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/MARIANA_text.svg"
                  class="figure-img img-fluid m-0" alt="">
              </figure>
            </div>
            <div
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
          </div>
          <div class="row row-cols-10">
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div
              id="pablo"
              @mouseover="textTeam('PABLO', '#pablo')"
              @focus="textTeam('PABLO', '#pablo')"
              class="col p-0"
            >
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/text/PABLO_text.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col p-0">
              <figure role="button" class="figure m-0">
                <img src="../assets/images/team/foto_transparent.svg"
                  class="figure-img img-fluid m-0" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="slider" class="dots">
      <button v-for="(_slide, idx) in dotHelper" @click="slider.moveToIdx(idx)"
        :class="{ dot: true, active: current === idx }" :key="idx"></button>
    </div>
  </div>

  <div class="container-fluid px-0">
    <div class="card bg-black">
      <div class="card-body position-relative">
        <div class="row justify-content-center pt-5">
          <div class="col-auto">
            <h1 class="text-white text-opacity-75 text-center futurism">
              THANKS
            </h1>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-7 offset-5">
            <div class="navigation-wrapper">
              <div ref="containerThanks" class="keen-slider">
                <div class="keen-slider__slide number-slide1 px-3">
                  <div class="row row-cols-10">
                    <div
                      @mouseover="fotosTeam(thanks.CESAR_PULIDO = false)"
                      @focus="fotosTeam(thanks.CESAR_PULIDO = false)"
                      @mouseout="fotosTeam(thanks.CESAR_PULIDO = true)"
                      @blur="fotosTeam(thanks.CESAR_PULIDO = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.CESAR_PULIDO"
                          src="../assets/images/thanks/01CESAR_PULIDO_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/01CESAR_PULIDO_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.WAHEED = false)"
                      @focus="fotosTeam(thanks.WAHEED = false)"
                      @mouseout="fotosTeam(thanks.WAHEED = true)"
                      @blur="fotosTeam(thanks.WAHEED = true)"
                      class="col p-0"
                      >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.WAHEED"
                          src="../assets/images/thanks/02WAHEED_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/02WAHEED_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.NACHO_WAHID = false)"
                      @focus="fotosTeam(thanks.NACHO_WAHID = false)"
                      @mouseout="fotosTeam(thanks.NACHO_WAHID = true)"
                      @blur="fotosTeam(thanks.NACHO_WAHID = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.NACHO_WAHID"
                          src="../assets/images/thanks/03NACHO_WAHID_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/03NACHO_WAHID_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.URSULA = false)"
                      @focus="fotosTeam(thanks.URSULA = false)"
                      @mouseout="fotosTeam(thanks.URSULA = true)"
                      @blur="fotosTeam(thanks.URSULA = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.URSULA"
                          src="../assets/images/thanks/04URSULA_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/04URSULA_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.ALVARO = false)"
                      @focus="fotosTeam(thanks.ALVARO = false)"
                      @mouseout="fotosTeam(thanks.ALVARO = true)"
                      @blur="fotosTeam(thanks.ALVARO = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.ALVARO" src="../assets/images/thanks/05ALVARO_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/05ALVARO_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                  </div>
                  <div class="row row-cols-10">
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.BEA = false)"
                      @focus="fotosTeam(thanks.BEA = false)"
                      @mouseout="fotosTeam(thanks.BEA = true)"
                      @blur="fotosTeam(thanks.BEA = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.BEA"
                          src="../assets/images/thanks/06BEA_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/06BEA_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.GONZALO = false)"
                      @focus="fotosTeam(thanks.GONZALO = false)"
                      @mouseout="fotosTeam(thanks.GONZALO = true)"
                      @blur="fotosTeam(thanks.GONZALO = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.GONZALO"
                          src="../assets/images/thanks/07GONZALO_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/07GONZALO_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.MARIA_MADRE_GONZALO = false)"
                      @focus="fotosTeam(thanks.MARIA_MADRE_GONZALO = false)"
                      @mouseout="fotosTeam(thanks.MARIA_MADRE_GONZALO = true)"
                      @blur="fotosTeam(thanks.MARIA_MADRE_GONZALO = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.MARIA_MADRE_GONZALO"
                          src="../assets/images/thanks/08MARIA_MADRE_GONZALO_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/08MARIA_MADRE_GONZALO_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.ISABEL = false)"
                      @focus="fotosTeam(thanks.ISABEL = false)"
                      @mouseout="fotosTeam(thanks.ISABEL = true)"
                      @blur="fotosTeam(thanks.ISABEL = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.ISABEL"
                          src="../assets/images/thanks/09ISABEL_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/09ISABEL_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.PEDRO = false)"
                      @focus="fotosTeam(thanks.PEDRO = false)"
                      @mouseout="fotosTeam(thanks.PEDRO = true)"
                      @blur="fotosTeam(thanks.PEDRO = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.PEDRO"
                          src="../assets/images/thanks/10PEDRO_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/10PEDRO_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
                <div class="keen-slider__slide number-slide2 px-3">
                  <div class="row row-cols-10">
                    <div
                      @mouseover="fotosTeam(thanks.PACO = false)"
                      @focus="fotosTeam(thanks.PACO = false)"
                      @mouseout="fotosTeam(thanks.PACO = true)"
                      @blur="fotosTeam(thanks.PACO = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.PACO"
                          src="../assets/images/thanks/11PACO_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/11PACO_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.LORENA = false)"
                      @focus="fotosTeam(thanks.LORENA = false)"
                      @mouseout="fotosTeam(thanks.LORENA = true)"
                      @blur="fotosTeam(thanks.LORENA = true)"
                      class="col p-0"
                      >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.LORENA"
                          src="../assets/images/thanks/12LORENA_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/12LORENA_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.ALEJANDRO = false)"
                      @focus="fotosTeam(thanks.ALEJANDRO = false)"
                      @mouseout="fotosTeam(thanks.ALEJANDRO = true)"
                      @blur="fotosTeam(thanks.ALEJANDRO = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.ALEJANDRO"
                          src="../assets/images/thanks/13ALEJANDRO_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/13ALEJANDRO_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.ANTONIO = false)"
                      @focus="fotosTeam(thanks.ANTONIO = false)"
                      @mouseout="fotosTeam(thanks.ANTONIO = true)"
                      @blur="fotosTeam(thanks.ANTONIO = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.ANTONIO"
                          src="../assets/images/thanks/14ANTONIO_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/14ANTONIO_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.CRIS = false)"
                      @focus="fotosTeam(thanks.CRIS = false)"
                      @mouseout="fotosTeam(thanks.CRIS = true)"
                      @blur="fotosTeam(thanks.CRIS = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.CRIS" src="../assets/images/thanks/15CRIS_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/15CRIS_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                  </div>
                  <div class="row row-cols-10">
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.MARIA = false)"
                      @focus="fotosTeam(thanks.MARIA = false)"
                      @mouseout="fotosTeam(thanks.MARIA = true)"
                      @blur="fotosTeam(thanks.MARIA = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.MARIA"
                          src="../assets/images/thanks/16MARIA_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/16MARIA_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.NICO = false)"
                      @focus="fotosTeam(thanks.NICO = false)"
                      @mouseout="fotosTeam(thanks.NICO = true)"
                      @blur="fotosTeam(thanks.NICO = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.NICO"
                          src="../assets/images/thanks/17NICO_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/17NICO_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.JULIETA = false)"
                      @focus="fotosTeam(thanks.JULIETA = false)"
                      @mouseout="fotosTeam(thanks.JULIETA = true)"
                      @blur="fotosTeam(thanks.JULIETA = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.JULIETA"
                          src="../assets/images/thanks/18JULIETA_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/18JULIETA_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.JUARMA = false)"
                      @focus="fotosTeam(thanks.JUARMA = false)"
                      @mouseout="fotosTeam(thanks.JUARMA = true)"
                      @blur="fotosTeam(thanks.JUARMA = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.JUARMA"
                          src="../assets/images/thanks/19JUARMA_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/19JUARMA_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.CARMEN = false)"
                      @focus="fotosTeam(thanks.CARMEN = false)"
                      @mouseout="fotosTeam(thanks.CARMEN = true)"
                      @blur="fotosTeam(thanks.CARMEN = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.CARMEN"
                          src="../assets/images/thanks/20CARMEN_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/20CARMEN_foto.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
                <div class="keen-slider__slide number-slide3 px-3">
                  <div class="row row-cols-10">
                    <div
                      @mouseover="fotosTeam(thanks.ALVAROi = false)"
                      @focus="fotosTeam(thanks.ALVAROi = false)"
                      @mouseout="fotosTeam(thanks.ALVAROi = true)"
                      @blur="fotosTeam(thanks.ALVAROi = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.ALVAROi"
                          src="../assets/images/thanks/21ALVARO_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/21ALVARO_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.blanco = false)"
                      @focus="fotosTeam(thanks.blanco = false)"
                      @mouseout="fotosTeam(thanks.blanco = true)"
                      @blur="fotosTeam(thanks.blanco = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.blanco"
                          src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.blanco = false)"
                      @focus="fotosTeam(thanks.blanco = false)"
                      @mouseout="fotosTeam(thanks.blanco = true)"
                      @blur="fotosTeam(thanks.blanco = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.blanco"
                          src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.blanco = false)"
                      @focus="fotosTeam(thanks.blanco = false)"
                      @mouseout="fotosTeam(thanks.blanco = true)"
                      @blur="fotosTeam(thanks.blanco = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.blanco"
                          src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.blanco = false)"
                      @focus="fotosTeam(thanks.blanco = false)"
                      @mouseout="fotosTeam(thanks.blanco = true)"
                      @blur="fotosTeam(thanks.blanco = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.blanco"
                          src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                  </div>
                  <div class="row row-cols-10">
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.NACHO = false)"
                      @focus="fotosTeam(thanks.NACHO = false)"
                      @mouseout="fotosTeam(thanks.NACHO = true)"
                      @blur="fotosTeam(thanks.NACHO = true)"
                      class="col p-0"
                      >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.NACHO"
                          src="../assets/images/thanks/22NACHO_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/thanks/22NACHO_blanco.png"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.blanco = false)"
                      @focus="fotosTeam(thanks.blanco = false)"
                      @mouseout="fotosTeam(thanks.blanco = true)"
                      @blur="fotosTeam(thanks.blanco = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.blanco"
                          src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.blanco = false)"
                      @focus="fotosTeam(thanks.blanco = false)"
                      @mouseout="fotosTeam(thanks.blanco = true)"
                      @blur="fotosTeam(thanks.blanco = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.blanco"
                          src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.blanco = false)"
                      @focus="fotosTeam(thanks.blanco = false)"
                      @mouseout="fotosTeam(thanks.blanco = true)"
                      @blur="fotosTeam(thanks.blanco = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.blanco"
                          src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div class="col p-0">
                      <figure role="button" class="figure m-0">
                        <img src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                    <div
                      @mouseover="fotosTeam(thanks.blanco = false)"
                      @focus="fotosTeam(thanks.blanco = false)"
                      @mouseout="fotosTeam(thanks.blanco = true)"
                      @blur="fotosTeam(thanks.blanco = true)"
                      class="col p-0"
                    >
                      <figure role="button" class="figure m-0">
                        <img v-if="thanks.blanco"
                          src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <img v-else src="../assets/images/team/foto_transparent.svg"
                          class="figure-img img-fluid m-0" alt="">
                        <figcaption class="figure-caption"></figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="sliderThanks" class="dots">
              <button v-for="(_slide, idx) in dotHelperThanks" @click="sliderThanks.moveToIdx(idx)"
                :class="{ dot: true, active: currentThanks === idx }" :key="idx"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="py-5 w-100"></div>
  <modal-container />
  <FooterSection :leng="datos.leng" />
  <Footer2Section :leng="datos.leng" />
</template>

<script setup>
import {
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  computed,
} from 'vue';
import { useRoute } from 'vue-router';
import NavBar from '@/components/NavBar.vue';
import FooterSection from '@/components/FooterSection.vue';
import Footer2Section from '@/components/Footer2Section.vue';
import { useKeenSlider } from 'keen-slider/vue.es';
import tippy from 'tippy.js';

const route = useRoute();

const props = defineProps({
  leng: {
    type: String,
  },
});

const datos = reactive({
  faqlogo: false,
  leng: '',
});

const leng = async () => {
  if (route.params.leng === 'zh') {
    datos.leng = 'zh';
  }
};

const fotos = reactive({
  JAVI: true,
  RAQUEL: true,
  NURIA: true,
  JOSECHU: true,
  DANI: true,
  CHRISTIAN: true,
  JUANDIEGO: true,
  CARLOSHURTADO: true,
  AMAIA: true,
  SONIA: true,
  JESUS: true,
  PABLO: true,
  MARIANA: true,
});

const thanks = reactive({
  CESAR_PULIDO: true,
  WAHEED: true,
  NACHO_WAHID: true,
  URSULA: true,
  ALVARO: true,
  BEA: true,
  GONZALO: true,
  MARIA_MADRE_GONZALO: true,
  ISABEL: true,
  PEDRO: true,
  PACO: true,
  LORENA: true,
  ALEJANDRO: true,
  ANTONIO: true,
  CRIS: true,
  MARIA: true,
  NICO: true,
  JULIETA: true,
  JUARMA: true,
  CARMEN: true,
  ALVAROi: true,
  NACHO: true,
  blanco: true,
});

const logoTeam = (el) => {
  datos.faqlogo = el;
};

const fotosTeam = () => {};

const textTeam = (nameTeam, tag) => {
  let label = '';
  let labelI = '';
  let labelII = '';
  let labelIII = '';
  let text = '';

  if (nameTeam === 'JAVI') {
    label = '';
    if (props.leng === 'zh') {
      labelI = '1971年2月15日';
      labelII = '项目 Alma Mater';
      labelIII = '创始人 - 艺术 - 创造';
      text = `具有国际职业生涯的视觉艺术家，目前正在梦想着Web3上时间的表现、
        元界的未来和区块链。`;
    } else {
      labelI = 'February 15th, 1971';
      labelII = 'Project Alma Mater';
      labelIII = 'Founder, art and creation';
      text = `Visual artist with international career, currently obsessed with the
        future of the metaverse, the Blockchain and the web3.`;
    }
  }

  if (nameTeam === 'RAQUEL') {
    if (props.leng === 'zh') {
      labelI = '1976年1月16日';
      labelII = '项目协调人';
      labelIII = '财务官员 - 房地产经纪人';
      text = `未来世界的粉丝（她像机器人Bender一样吃饭）和经纪人。她将她的房地产经验转移到虚拟世界。
        始终保持与2月15日的联系。`;
    } else {
      labelI = 'January 16th, 1976';
      labelII = 'Project coordination';
      labelIII = 'Financial Officer - Realtor';
      text = `Futurama fan (she feeds like Bender) and broker. She transfers her Real
        State experience to virtual worlds. Always in tune with February 15th.`;
    }
  }

  if (nameTeam === 'NURIA') {
    if (props.leng === 'zh') {
      labelI = '1963年10月29日';
      labelII = '内容开发和文案撰写';
      labelIII = '';
      text = `翻译、牡蛎农民和最有好奇心的人，特别是对技术感兴趣。她专注于沉浸在牡蛎中的沟通。
        在网络上使一家小食品公司发展壮大，并在元界存在之前设计了元界。`;
    } else {
      labelI = 'October 29th 1963';
      labelII = 'Content development and Copywriting';
      labelIII = '';
      text = `Oyster farmer, translator, teacher and above all curious. Pioneer in the
        digitalization of companies. She devised the metaverse before it existed.`;
    }
  }

  if (nameTeam === 'JOSECHU') {
    if (props.leng === 'zh') {
      labelI = '1966年3月18日';
      labelII = '后期制作 - 视觉效果 - 艺术';
      labelIII = '';
      text = `聪明和熟练的视觉艺术家。当他工作时，时间停止，他最大限度地关注细节。
        他的猫王式头发始终粘在电脑监视器/电脑屏幕上。`;
    } else {
      labelI = 'March 18th 1966';
      labelII = 'Post-production - Visual Effects - Art';
      labelIII = '';
      text = `Intelligent and skillful visual artist. When he works, time stops and he
        takes care of detail to the maximum. His Elvis style hair lives always glued to
        computer screens.`;
    }
  }

  if (nameTeam === 'DANI') {
    if (props.leng === 'zh') {
      labelI = '1997年5月30日';
      labelII = '3D绘图设计师，艺术家的儿子和孙子。';
      labelIII = '';
      text = `3D绘图设计师，艺术家的儿子和孙子。音乐狂热者，他几乎和设计一样多地打鼓。他以自己的方式交往，
        因此他可能不会在我们的 Discord 上露面。`;
    } else {
      labelI = 'May, 30th 1997';
      labelII = '3D designer, son and grandson of artists';
      labelIII = '';
      text = `3D designer, son and grandson of artists. A music freak, he plays drums
        almost as much as he designs. He socializes in his own way so he probably won't
        show up much on our Discord.`;
    }
  }

  if (nameTeam === 'CHRISTIAN') {
    if (props.leng === 'zh') {
      labelI = '1978 年 7 月 28 日';
      labelII = '商业顾问';
      labelIII = '';
      text = `硬件科技企业家和大胆的 NFT 收藏者。在中国、美国和拉美之间，
        他不断在尝试值得冒险的事情。`;
    } else {
      labelI = 'July, 28th 1978';
      labelII = 'Business Consulting';
      labelIII = '';
      text = `Hardware technology entrepreneur and bold NFT collector. Between China,
        USA and Latin America, he keeps taking risks that are worth taking.`;
    }
  }

  if (nameTeam === 'JUANDIEGO') {
    if (props.leng === 'zh') {
      labelI = '2000 年 10 月 24 日';
      labelII = '社区管理 - 区块链';
      labelIII = '';
      text = `年轻，有才华，准备充分。从中国到荷兰和牛津，拥有巴黎最美的景象，他管理
        Tempoland 社区，让我们处在当代的现实中。`;
    } else {
      labelI = 'October 24th 2000';
      labelII = 'Community Manager - Blockchain';
      labelIII = '';
      text = `Young, talented and well prepared. From China to the Netherlands and
        Stanford and with the best views of Paris, he manages the Tempoland community
        and places us in the reality of our time.`;
    }
  }

  if (nameTeam === 'CARLOSHURTADO') {
    if (props.leng === 'zh') {
      labelI = '1960 年 11 月 7 日';
      labelII = 'Producer, screenwriter';
      labelIII = '';
      text = `制片人，编剧，声音设计师和声音小说专家。他创造了许多正在活跃中的人才，
        也是我们的声音祖先。`;
    } else {
      labelI = 'November 7th 1960';
      labelII = 'Producer, screenwriter';
      labelIII = '';
      text = `Producer, screenwriter, sound designer and expert in sound fiction. He
        has created Talents in action and is our grandfather of sound.`;
    }
  }

  if (nameTeam === 'AMAIA') {
    if (props.leng === 'zh') {
      labelI = 'JULY 17TH, 1988';
      labelII = 'CREATIVE AND DESIGNER';
      labelIII = '';
      text = `As a translator of ideas, she has no difficulty in switching between the
        metaverse and the physical world. Her embroidery hands have filled the design
        of this world we are creating with details`;
    } else {
      labelI = 'JULY 17TH, 1988';
      labelII = 'CREATIVE AND DESIGNER';
      labelIII = '';
      text = `As a translator of ideas, she has no difficulty in switching between the
        metaverse and the physical world. Her embroidery hands have filled the design
        of this world we are creating with details`;
    }
  }

  if (nameTeam === 'SONIA') {
    if (props.leng === 'zh') {
      labelI = 'OCTOBER 23TH, 1979';
      labelII = 'PRODUCER ART AND SOCIAL MEDIA SUPORTER';
      labelIII = '';
      text = `Cultural manager who knows how to work in a team. She ensures that
        communication and good vibes flow in Tempoland. Trostky, her dog, is her
        best assistant.`;
    } else {
      labelI = 'OCTOBER 23TH, 1979';
      labelII = 'PRODUCER ART AND SOCIAL MEDIA SUPORTER';
      labelIII = '';
      text = `Cultural manager who knows how to work in a team. She ensures that
        communication and good vibes flow in Tempoland. Trostky, her dog, is her
        best assistant.`;
    }
  }

  if (nameTeam === 'JESUS') {
    if (props.leng === 'zh') {
      labelI = 'May 10th, 1972';
      labelII = 'Meta-Architect';
      labelIII = '';
      text = `Has lived in cities such as New York, Tokyo and Madrid. Now he has
        moved to Tempoland and is a meta-architect, a key part of the Tempoland
        ecosystem. His experience and advice have strengthened the foundations
        of our project.`;
    } else {
      labelI = 'May 10th, 1972';
      labelII = 'Meta-Architect';
      labelIII = '';
      text = `Has lived in cities such as New York, Tokyo and Madrid. Now he has
        moved to Tempoland and is a meta-architect, a key part of the Tempoland
        ecosystem. His experience and advice have strengthened the foundations
        of our project.`;
    }
  }

  if (nameTeam === 'PABLO') {
    if (props.leng === 'zh') {
      labelI = '1969年9月25日';
      labelII = '经济学家，对技术痴迷。';
      labelIII = '';
      text = `物流专家，目前正在建立欧洲和亚洲之间的桥梁。认为光头有一个重要的优势：
        你不花钱买洗发水，你可以买nfts。`;
    } else {
      labelI = 'September 25th, 1969';
      labelII = 'Economist and obsessed with technology';
      labelIII = '';
      text = `Logistics expert and currently building bridges between Europe and Asia.
        Thinks that being bald has an important advantage: you don't spend on shampoo,
        you can buy nfts.`;
    }
  }

  if (nameTeam === 'MARIANA') {
    if (props.leng === 'zh') {
      labelI = 'May 6th, 1972';
      labelII = 'Organization And Logistics';
      labelIII = '';
      text = `A fighter with strong ideas, she was one of the first to lose her wallet's
        seed phrase when bitcoin was at $100. She didn't really care since what she likes
        to do is play and participate.`;
    } else {
      labelI = 'May 6th, 1972';
      labelII = 'Organization And Logistics';
      labelIII = '';
      text = `A fighter with strong ideas, she was one of the first to lose her wallet's
        seed phrase when bitcoin was at $100. She didn't really care since what she likes
        to do is play and participate.`;
    }
  }

  if (text !== '') {
    tippy(tag, {
      content: `
        <div class="row bg-black p-3" style="position:relative; z-index:800; width: 100%;">
          <div class="col">
            <p class="text-white text-opacity-75 fs-4 futurism">
              ${label}
            </p>
            <p class="text-white text-opacity-75 fs-5 futurism">
              ${labelI}
            </p>
            <p class="text-white text-opacity-75 fs-5 futurism">
              ${labelII}
            </p>
            <p class="text-white text-opacity-75 fs-5 futurism">
              ${labelIII}
            </p>
            <p class="text-white text-opacity-75 fs-6 sofiaExtraLight mt-3">
              ${text}
            </p>
          </div>
        </div>
      `,
      allowHTML: true,
      placement: 'top',
    });
  }
};

const current = ref(0);
const [container, slider] = useKeenSlider({
  initial: current.value,
  slideChanged: (s) => {
    current.value = s.track.details.rel;
  },
});

const currentThanks = ref(0);
const [containerThanks, sliderThanks] = useKeenSlider({
  initial: currentThanks.value,
  slideChanged: (s) => {
    currentThanks.value = s.track.details.rel;
  },
});

const dotHelper = computed(() => {
  const re = slider.value ? [...Array(slider.value.track.details.slides.length).keys()] : [];
  return re;
});

const dotHelperThanks = computed(() => {
  const re = sliderThanks.value
    ? [...Array(sliderThanks.value.track.details.slides.length).keys()] : [];
  return re;
});

onBeforeMount(async () => {
});

onMounted(async () => {
  await leng();
});
</script>

<style scoped lang="scss">
.navigation-wrapper {
  position: relative;
  padding: 0;
}
.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}
.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}
.dot:focus {
  outline: none;
}
.dot.active {
  background: rgb(0, 159, 227);
}
.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #fff;
  cursor: pointer;
}
.arrow--left {
  left: 5px;
}
.arrow--right {
  left: auto;
  right: 5px;
}
.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}
</style>
