<template>
  <div class="redes pt-5">
    <div v-if="props.leng === 'zh'" class="row pb-3 justify-content-end">
      <div class="col-auto p-0">
        <a class="nav-link fs-6 text-white-50" href="https://croonos.io/home"
        >
          EN
        </a>
      </div>
      <div class="col-auto">
        <a class="nav-link fs-6 text-white-80" href="https://croonos.io/zh/home"
        >
          CH
        </a>
      </div>
    </div>
    <div v-else class="row pb-3 justify-content-end">
      <div class="col-auto p-0">
        <a class="nav-link fs-6 text-white-80" href="https://croonos.io/home"
        >
          EN
        </a>
      </div>
      <div class="col-auto">
        <a class="nav-link fs-6 text-white-50" href="https://croonos.io/zh/home"
        >
          CH
        </a>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-auto pe-0">
        <button type="button" class="btn" @click="redes('discord')" @keyup="redes('discord')">
          <BootstrapIcon icon="discord" size="1x" variant="secondary" />
        </button>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-auto pe-0">
        <button type="button" class="btn" @click="redes('twitter')" @keyup="redes('twitter')">
          <BootstrapIcon icon="twitter" size="1x" variant="secondary" />
        </button>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-auto pe-0">
        <button type="button" class="btn" @click="redes('medium')" @keyup="redes('medium')">
          <BootstrapIcon icon="medium" size="1x" variant="secondary" />
        </button>
      </div>
    </div>
  </div>
  <div
    id="home"
    @mouseover="rTg8()"
    @focus="rTg8()"
    class="container-fluid home px-0 position-relative"
  >
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-md-7 mt-5 pt-5">
        <div class="container">
          <div class="row justify-content-center align-items-end">
            <div
              @mouseover="logoH(true)"
              @focus="logoH(true)"
              @mouseout="logoH(false)"
              @blur="logoH(false)"
              class="col-1"
            >
              <figure role="button" class="figure">
                <img v-if="datos.mlogo" src="../assets/images/logo_180.gif"
                  class="figure-img img-fluid logo" alt="">
                <img v-else src="../assets/images/logo.gif"
                  class="figure-img img-fluid logo" alt="">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="col-10 col-md-8 p-0">
              <figure class="figure" style="min-width:100%;">
                <img src="../assets/images/tempoland.gif"
                  class="figure-img img-fluid" alt="" style="min-width:100%;">
                <figcaption class="figure-caption"></figcaption>
              </figure>
            </div>
            <div class="w-100"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center align-items-center position-absolute w-100">
      <div class="col-12 col-md-7 p-0">
        <div class="container">
          <div class="row justify-content-center align-items-end">
            <div class="col-10 col-md-8 offset-1 p-0 pe-5">
             <p v-if="props.leng === 'zh'"
              class="card-title text-white text-opacity-75 ms-3 sofiaExtraLight
                text-justify text-home anime-text-home"
              >
                零年，一个新的时间生态系统即将开启。我们，被时间造就的生物，正在组织起来成为掌控未来的主宰者。
             </p>
             <p v-else class="card-title text-white text-opacity-75 ms-3 sofiaExtraLight
                text-justify text-home anime-text-home"
              >
                Year Zero, a new temporal ecosystem begins. We, creatures made of time, are getting
                organized to be masters of our future.
             </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center align-items-end">
      <div class="col-12 p-0">
        <video id="calendario-solo" autoplay playsinline muted preload="metadata" loop
          poster="../assets/videos/master_web_fondo.png">
          <source src="../assets/videos/master_web_fondo.mp4" type="video/mp4">
          Su navegador no soporta contenido multimedia.
          <track src="" kind="captions" srclang="es" label="">
        </video>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  onBeforeMount,
  onMounted,
  reactive,
} from 'vue';
// import Animation from '@/composables/Animate';
import { gsap } from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(TextPlugin, SplitText);

const props = defineProps({
  leng: {
    type: String,
  },
  muted: {
    type: Boolean,
  },
});

const datos = reactive({
  mlogo: false,
  showMenu: false,
});

const redes = async (red) => {
  // if (red === 'discord') { }
  if (red === 'twitter') {
    window.open('https://twitter.com/@_tempoland_', '_blank');
  }
  // if (red === 'medium') { }
};

const rTg8 = async () => {
  const generico = document.getElementById('audio-generico-dias-tem');
  const tg8 = document.getElementById('audio-tg-8');
  if (props.muted === false) {
    await generico.pause();
    generico.muted = true;
    await tg8.play();
    tg8.muted = false;
  }
};

const logoH = (el) => {
  datos.mlogo = el;
};

const setOffScreenRight = (el) => {
  const rect = el.getBoundingClientRect();
  const dright = window.screen.width - rect.left + el.offsetWidth / 2;
  return dright;
};

const setOffScreenLeft = (el) => {
  const rect = el.getBoundingClientRect();
  const dleft = -window.screen.width + rect.left + el.offsetWidth / 2;
  return dleft;
};

const animacionesTitlesHome = () => {
  const tl = gsap.timeline();
  // eslint-disable-next-line
  const splitText = new SplitText('.anime-titles-home', {
    type: 'words,chars',
    charsClass: 'all char++',
  });
  tl.set('.char7, .char8, .char9', {
    x: (i, t) => {
      const right = setOffScreenRight(t);
      return right;
    },
  });
  tl.set('.char1, .char2, .char3, .char4, .char5', {
    x: (i, t) => {
      const left = setOffScreenLeft(t);
      return left;
    },
  });
  tl.set('.anime-titles-home', { autoAlpha: 1 });
  tl.set('.char6', { y: -100, autoAlpha: 0 });
  tl.to('.all:not(.char6)', {
    x: 0,
    stagger: {
      each: 0.2,
      from: 'random',
      ease: 'power2.inOut',
    },
  }).to('.char6', {
    y: 0,
    autoAlpha: 1,
    duration: 1,
    ease: 'back.out(2)',
  }).to('.all', {
    color: 'grey',
    duration: 0.1,
    repeat: 1,
    yoyo: true,
    repeatDelay: 1,
    stagger: 0.05,
  });
};

const animacionesTextHome = () => {
  const words = gsap.utils.toArray('.anime-text-home');
  const tl = gsap.timeline({ delay: 0.5 });
  const timePerCharacter = 0.1;

  words.forEach((el) => {
    tl.from(el, { text: '', duration: el.innerHTML.length * timePerCharacter, ease: 'none' });
  });
};

const calendario = () => {
  const calendarioSolo = document.getElementById('calendario-solo');
  calendarioSolo.playbackRate = 1;
  calendarioSolo.addEventListener('timeupdate', () => {
    if (calendarioSolo.currentTime >= 24.5) {
      calendarioSolo.pause();
    }
  });
};

onBeforeMount(() => {
});

onMounted(() => {
  calendario();
  animacionesTitlesHome();
  animacionesTextHome();
});
</script>

<style scoped lang="scss">

.header-imagen {
  display: block;
  width: 100%;
  background: url("../assets/images/canon.png") no-repeat top;
  top: 0;
  right: 0;
  left: 0;
  background-size: cover;
}

@media (min-width: 1025px) and (min-height: 1367px) {
  .home {
    height:100vh; min-width: 100vw;
  }
}
</style>
