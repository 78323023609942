<template>
  <div class="w-100">
    <div class="card bg-black p-0">
      <div class="card-body p-0 position-relative">
        <div class="row position-absolute h-100 w-100 m-0" style="z-index: 9;">
          <div class="col-12 p-0 h-100 marquesina">
          </div>
        </div>
        <div class="row position-static m-0">
          <div class="col-12 p-0">
            <div v-if="props.leng === 'zh'" ref="sliderIIIzh" class="keen-slider">
              <div class="keen-slider__slide" style="min-width: 440px; max-width: 440px">
                <figure class="figure">
                  <img src="../assets/images/are_you_a_time_creature_zh.svg"
                    class="figure-img img-fluid" alt="">
                  <figcaption class="figure-caption"></figcaption>
                </figure>
              </div>
              <div class="keen-slider__slide" style="min-width: 440px; max-width: 440px">
                <figure class="figure">
                  <img src="../assets/images/are_you_a_time_creature_zh.svg"
                    class="figure-img img-fluid" alt="">
                  <figcaption class="figure-caption"></figcaption>
                </figure>
              </div>
              <div class="keen-slider__slide" style="min-width: 440px; max-width: 440px">
                <figure class="figure">
                  <img src="../assets/images/are_you_a_time_creature_zh.svg"
                    class="figure-img img-fluid" alt="">
                  <figcaption class="figure-caption"></figcaption>
                </figure>
              </div>
              <div class="keen-slider__slide" style="min-width: 440px; max-width: 440px">
                <figure class="figure">
                  <img src="../assets/images/are_you_a_time_creature_zh.svg"
                    class="figure-img img-fluid" alt="">
                  <figcaption class="figure-caption"></figcaption>
                </figure>
              </div>
            </div>
            <div v-else ref="sliderIII" class="keen-slider">
              <div class="keen-slider__slide" style="min-width: 850px; max-width: 850px">
                <figure class="figure">
                  <img src="../assets/images/are_you_a_time_creature.svg"
                    class="figure-img img-fluid" alt="">
                  <figcaption class="figure-caption"></figcaption>
                </figure>
              </div>
              <div class="keen-slider__slide" style="min-width: 850px; max-width: 850px">
                <figure class="figure">
                  <img src="../assets/images/are_you_a_time_creature.svg"
                    class="figure-img img-fluid" alt="">
                  <figcaption class="figure-caption"></figcaption>
                </figure>
              </div>
              <div class="keen-slider__slide" style="min-width: 850px; max-width: 850px">
                <figure class="figure">
                  <img src="../assets/images/are_you_a_time_creature.svg"
                    class="figure-img img-fluid" alt="">
                  <figcaption class="figure-caption"></figcaption>
                </figure>
              </div>
              <div class="keen-slider__slide" style="min-width: 850px; max-width: 850px">
                <figure class="figure">
                  <img src="../assets/images/are_you_a_time_creature.svg"
                    class="figure-img img-fluid" alt="">
                  <figcaption class="figure-caption"></figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="timeCreatures" class="container-fluid pb-2 px-0 position-relative">
    <div class="row position-absolute h-100 w-100 mt-5 m-0 p-0 pt-4" style="z-index: 9;">
      <div class="col-12 bg-black bg-opacity-75 mt-5 p-0 w-100" style="height: 82%;">
        <div class="row justify-content-center align-items-center
          position-absolute h-100 w-100 p-0" style="z-index: 10;">
          <div class="col-12">
            <h1 class="text-warning text-center futurism">
              COMING SOON
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="card bg-black position-static">
      <div class="card-body p-0">
        <div class="container">
          <div class="card bg-black">
            <div class="card-body p-0 position-relative">
              <div class="row justify-content-center align-items-center
                position-absolute bottom-0 end-0">
                <div class="col-10 offset-2 col-md-5">
                  <video id="explosion_gris" autoplay playsinline muted preload="metadata" loop
                    poster="../assets/videos/explosion_gris.png">
                    <source src="../assets/videos/explosion_gris.mp4" type="video/mp4">
                    Su navegador no soporta contenido multimedia.
                    <track src="" kind="captions" srclang="es" label="">
                  </video>
                </div>
              </div>
              <div class="row justify-content-center align-items-center pt-5 position-static">
                <div class="col-10 col-md-6">
                  <div v-if="props.leng === 'zh'" class="row">
                    <div v-tracker="text2True" class="col-12">
                      <h2 class="text-primary fw-bold
                        pt-5 futurism anime-titles-timecreatures2">
                        时空生物
                      </h2>
                    </div>
                    <div v-tracker="text3True" class="col-12 pt-5">
                     <p class="text-white text-opacity-75 pt-5 futurism
                        fw-bold anime-titles-timecreatures3"
                      >
                        时间诞生
                     </p>
                     <p class="text-white text-opacity-75 pt-3 sofiaExtraLight
                        text-justify anime-titles-timecreatures4 fw-bold">
                        时空生物在社区的手中经历了一种蜕变。第一批Tempogenesis是非常的特别，它们是完全独特，手工制作的4D存在。
                        它们在流体中被部分隐藏，开始慢慢展示一些决定其特征和稀有性的特点。一旦它们的日期在零年历中被预订，它们将成为第一批时间掌控者。
                     </p>
                    </div>
                    <div class="col-12" style="height: 15rem;"></div>
                  </div>
                  <div v-else class="row">
                    <div v-tracker="text2True" class="col-12">
                      <h2 class="text-primary fw-bold
                        pt-5 futurism anime-titles-timecreatures2">
                        Time Creatures
                      </h2>
                    </div>
                    <div v-tracker="text3True" class="col-12 pt-5">
                     <p class="text-white text-opacity-75 pt-5 futurism
                        fw-bold anime-titles-timecreatures3"
                      >
                        TEMPO:GENESIS
                     </p>
                     <p class="text-white text-opacity-75 pt-3 sofiaExtraLight
                        text-justify anime-titles-timecreatures4 fw-bold">
                        Time creatures experience a metamorphosis in the hands of the
                        community. The first Tempogenesis are very special, totally
                        unique, hand-made 4D beings. Partially hidden each one in its
                        fluid, they start to reveal some of the traits that will
                        determine their character and rarity. As soon as their date
                        is booked in the year zero calendar, they become the first
                        time-keepers.
                     </p>
                    </div>
                    <div class="col-12" style="height: 15rem;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card bg-black">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-12 p-0 position-relative" style="z-index: 10;">
            <div class="row position-absolute h-100 w-100 m-0 p-0" style="z-index: 11;">
              <div class="col-12 bg-black bg-opacity-75 p-0 w-100" style="height: 48%;">
              </div>
              <div class="col-12 p-0 w-100" style="height: 4%;">
              </div>
              <div class="col-12 bg-black bg-opacity-75 p-0 w-100" style="height: 48%;">
              </div>
            </div>
            <div ref="sliderIIII" class="keen-slider position-static">
              <div class="keen-slider__slide">
                <video autoplay playsinline muted preload="metadata" loop
                  poster="../assets/videos/NFTS_Tempogenesis/TG(11).png">
                  <source src="../assets/videos/NFTS_Tempogenesis/TG(11).mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
              </div>
              <div class="keen-slider__slide">
                <video autoplay playsinline muted preload="metadata" loop
                  poster="../assets/videos/NFTS_Tempogenesis/TG(12).png">
                  <source src="../assets/videos/NFTS_Tempogenesis/TG(12).mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
              </div>
              <div class="keen-slider__slide">
                <video autoplay playsinline muted preload="metadata" loop
                  poster="../assets/videos/NFTS_Tempogenesis/TG(14).png">
                  <source src="../assets/videos/NFTS_Tempogenesis/TG(14).mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
              </div>
              <div class="keen-slider__slide">
                <video autoplay playsinline muted preload="metadata" loop
                  poster="../assets/videos/NFTS_Tempogenesis/TG(15).png">
                  <source src="../assets/videos/NFTS_Tempogenesis/TG(15).mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
              </div>
              <div class="keen-slider__slide">
                <video autoplay playsinline muted preload="metadata" loop
                  poster="../assets/videos/NFTS_Tempogenesis/TG(16).png">
                  <source src="../assets/videos/NFTS_Tempogenesis/TG(16).mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
              </div>
              <div class="keen-slider__slide">
                <video autoplay playsinline muted preload="metadata" loop
                  poster="../assets/videos/NFTS_Tempogenesis/TG(18).png">
                  <source src="../assets/videos/NFTS_Tempogenesis/TG(18).mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
              </div>
              <div class="keen-slider__slide">
                <video autoplay playsinline muted preload="metadata" loop
                  poster="../assets/videos/NFTS_Tempogenesis/TG(20).png">
                  <source src="../assets/videos/NFTS_Tempogenesis/TG(20).mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
              </div>
              <div class="keen-slider__slide">
                <video autoplay playsinline muted preload="metadata" loop
                  poster="../assets/videos/NFTS_Tempogenesis/TG(24).png">
                  <source src="../assets/videos/NFTS_Tempogenesis/TG(24).mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
              </div>
              <div class="keen-slider__slide">
                <video autoplay playsinline muted preload="metadata" loop
                  poster="../assets/videos/NFTS_Tempogenesis/TG(29).png">
                  <source src="../assets/videos/NFTS_Tempogenesis/TG(29).mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
              </div>
              <div class="keen-slider__slide">
                <video autoplay playsinline muted preload="metadata" loop
                  poster="../assets/videos/NFTS_Tempogenesis/TG(33).png">
                  <source src="../assets/videos/NFTS_Tempogenesis/TG(33).mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
              </div>
              <div class="keen-slider__slide">
                <video autoplay playsinline muted preload="metadata" loop
                  poster="../assets/videos/NFTS_Tempogenesis/TG(40).png">
                  <source src="../assets/videos/NFTS_Tempogenesis/TG(40).mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
              </div>
              <div class="keen-slider__slide">
                <video autoplay playsinline muted preload="metadata" loop
                  poster="../assets/videos/NFTS_Tempogenesis/TG(43).png">
                  <source src="../assets/videos/NFTS_Tempogenesis/TG(43).mp4" type="video/mp4">
                  Su navegador no soporta contenido multimedia.
                  <track src="" kind="captions" srclang="es" label="">
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';
import Animation from '@/composables/Animate';
import { useKeenSlider } from 'keen-slider/vue';

const props = defineProps({
  leng: {
    type: String,
  },
});

const animation = { duration: 90000, easing: (t) => t };

const [sliderIII] = useKeenSlider({
  loop: true,
  mode: 'snap',
  renderMode: 'performance',
  drag: false,
  slides: { perView: 'auto' },
  created(s) {
    s.moveToIdx(5, true, animation);
  },
  updated(s) {
    s.moveToIdx(s.track.details.abs + 5, true, animation);
  },
  animationEnded(s) {
    s.moveToIdx(s.track.details.abs + 5, true, animation);
  },
});

const [sliderIIIzh] = useKeenSlider({
  loop: true,
  mode: 'snap',
  renderMode: 'performance',
  drag: false,
  slides: { perView: 'auto' },
  created(s) {
    s.moveToIdx(5, true, animation);
  },
  updated(s) {
    s.moveToIdx(s.track.details.abs + 1, true, animation);
  },
  animationEnded(s) {
    s.moveToIdx(s.track.details.abs + 1, true, animation);
  },
});

const [sliderIIII] = useKeenSlider(
  {
    loop: true,
    breakpoints: {
      '(min-width: 400px)': {
        slides: { perView: 4, spacing: 0 },
      },
      '(min-width: 1000px)': {
        slides: { perView: 7, spacing: 0 },
      },
    },
    created(s) {
      s.moveToIdx(5, true, animation);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
  },
  /* [
    (slider) => {
      let timeout;
      let mouseOver = false;
      function clearNextTimeout() {
        clearTimeout(timeout);
      }
      function nextTimeout() {
        clearTimeout(timeout);
        if (mouseOver) return;
        timeout = setTimeout(() => {
          slider.next();
        }, 2000);
      }
      slider.on('created', () => {
        slider.container.addEventListener('mouseover', () => {
          mouseOver = true;
          clearNextTimeout();
        });
        slider.container.addEventListener('mouseout', () => {
          mouseOver = false;
          nextTimeout();
        });
        nextTimeout();
      });
      slider.on('dragStarted', clearNextTimeout);
      slider.on('animationEnded', nextTimeout);
      slider.on('updated', nextTimeout);
    },
  ], */
);

const titlesTimecreatures1 = ref(1);
const titlesTimecreatures2 = ref(1);
const titlesTimecreatures3 = ref(1);

const datos = reactive({
  count1: 0,
  count2: 0,
  count3: 0,
});

const animacionesTimecreatures1 = () => {
  const timecreatures = document.querySelectorAll('.anime-titles-timecreatures1');
  Animation.enterBottom(timecreatures);
};

const animacionesTimecreatures2 = () => {
  const timecreatures2 = document.querySelectorAll('.anime-titles-timecreatures2');
  Animation.enterBottom(timecreatures2);
  const timecreatures3 = document.querySelectorAll('.anime-titles-timecreatures3');
  Animation.enterBottom(timecreatures3);
};

const animacionesTimecreatures3 = () => {
  const timecreatures4 = document.querySelectorAll('.anime-titles-timecreatures4');
  Animation.enterBottom(timecreatures4);
};

const text2True = ({ up2 }) => {
  if (datos.count2 === 1) {
    titlesTimecreatures2.value = up2;
  }
  if (datos.count2 === 0) {
    datos.count2 += 1;
  }
};

const text3True = ({ up2 }) => {
  if (datos.count3 === 1) {
    titlesTimecreatures3.value = up2;
  }
  if (datos.count3 === 0) {
    datos.count3 += 1;
  }
};

watch(titlesTimecreatures1, (value) => {
  if (value === 0) {
    animacionesTimecreatures1();
  }
});

watch(titlesTimecreatures2, (value) => {
  if (value === 0) {
    animacionesTimecreatures2();
  }
});

watch(titlesTimecreatures3, (value) => {
  if (value === 0) {
    animacionesTimecreatures3();
  }
});

onBeforeMount(() => {
});

onMounted(() => {
});
</script>

<style scoped lang="scss">
</style>
