<template>
  <div class="w-100 sticky-top p-0 bg-black bg-opacity-10">
    <div class="container p-0" v-if="false">
      <div class="row row-cols-2 justify-content-end p-0">
        <div class="col-2 p-0">
          <video id="maquina_header" autoplay playsinline muted preload="metadata" loop>
            <source src="../assets/videos/maquina_header.webm" type="video/mp4">
            Su navegador no soporta contenido multimedia.
          </video>
        </div>
        <div class="col-auto">
          <h6 class="text-danger futurism">
            {{ datos.hour.toString().padStart(2) }}
            :{{ datos.min.toString().padStart(2) }}
            :{{ datos.sec.toString().padStart(2) }}
          </h6>
        </div>
      </div>
    </div>
    <nav class="navbar navbar-expand-lg navbar-black bg-black bg-opacity-10">
      <div class="container-fluid px-4">
        <router-link
          to="#home"
          class="navbar-brand"
          @click="scrollInto('#home')"
          @mouseover="logoN(true)"
          @focus="logoN(true)"
          @mouseout="logoN(false)"
          @blur="logoN(false)"
        >
          <img v-if="datos.navlogo" src="../assets/images/logo_180.gif" alt="Logo"
            width="8" height="20" class="d-inline-block align-text-top">
          <img v-else src="../assets/images/logo.gif" alt="Logo"
            width="8" height="20" class="d-inline-block align-text-top">
        </router-link>
        <router-link
          to=""
          @click.prevent="showOffcanvasMenu(true)"
          role="button"
          class="nav-link badge text-wrap futurism"
          style="width: 4rem;"
        >
          Connect Wallet
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul v-if="props.leng === 'zh'" class="navbar-nav ms-auto"><!-- nav-pills -->
            <li class="nav-item">
              <router-link
                :to="{ path: '/zh/home', hash: '#tempoLand' }"
                class="nav-link futurism anime-titles-navbar">
                Tempoland
              </router-link>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link futurism">|</a>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ path: '/zh/home', hash: '#timeCreatures' }"
                class="nav-link futurism anime-titles-navbar">
                时空生物
              </router-link>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link futurism">|</a>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ path: '/zh/home', hash: '#chain-events' }"
                class="nav-link futurism anime-titles-navbar">
                事件链
              </router-link>
            </li>
          </ul>
          <ul v-else class="navbar-nav ms-auto"><!-- nav-pills -->
            <li class="nav-item">
              <router-link
                :to="{ path: '/home', hash: '#tempoLand' }"
                class="nav-link futurism anime-titles-navbar">
                Tempoland
              </router-link>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link futurism">|</a>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ path: '/home', hash: '#timeCreatures' }"
                class="nav-link futurism anime-titles-navbar">
                Time creatures
              </router-link>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link futurism">|</a>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ path: '/home', hash: '#chain-events' }"
                class="nav-link futurism anime-titles-navbar">
                Chain of events
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="row p-0" style="min-width: 100%;">
      <div class="col p-0">
        <scroll-progress-bar
          @complete="handleComplete"
          height="0.1rem"
          backgroundColor="rgba(255, 255, 0, 0.6)"
          containerClass="false" />
      </div>
    </div>
    <div class="offcanvas offcanvas-start"
      :class="datos.showMenu ? 'show' : ''" tabindex="-1"
      :style="{ visibility: datos.showMenu ? 'visible' : 'hidden' }">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title">Coming Soon</h5>
        <button type="button" class="btn-close text-reset"
          @click.prevent="showOffcanvasMenu(false)"></button>
      </div>
      <div class="offcanvas-body">
        <p>.....</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  onBeforeMount,
  onMounted,
  reactive,
} from 'vue';
import Animation from '@/composables/Animate';
import ScrollProgressBar from '@/components/ScrollProgressBar.vue';

const props = defineProps({
  leng: {
    type: String,
  },
});

const datos = reactive({
  sec: 0,
  min: 0,
  hour: 0,
  timer: null,
  intervalList: [],
  navlogo: false,
  showMenu: false,
});

const showOffcanvasMenu = (status) => {
  datos.showMenu = status;
};

const logoN = (el) => {
  datos.navlogo = el;
};

const playing = () => {
  datos.sec += 1;
  if (datos.sec >= 59) {
    datos.sec = 0;
    datos.min += 1;
  }
  if (datos.min >= 59) {
    datos.min = 0;
    datos.hour += 1;
  }
};

const playCronoetro = () => {
  if (datos.timer === null) {
    playing();
    datos.timer = setInterval(() => playing(), 1000);
  } else {
    clearInterval(datos.timer);
    datos.timer = null;
    // pause();
  }
};

const scrollInto = (elementId) => {
  const section = document.querySelector(`${elementId}`);
  section.scrollIntoView({
    behavior: 'smooth',
  });
};

const animacionesTextNavbar = () => {
  const titlesHome = document.querySelectorAll('.anime-titles-navbar');
  titlesHome.forEach((text) => {
    Animation.WordShuffler(
      text,
      {
        textColor: 'rgba(255,255,255,0.7)',
        timeOffset: 2,
        mixCapital: true,
        mixSpecialCharacters: true,
      },
      true,
    );
  });
  const parrafoHome = document.querySelectorAll('.anime-text-navbar');
  parrafoHome.forEach((text) => {
    Animation.WordShuffler(
      text,
      {
        textColor: 'rgba(255,255,255,0.7)',
        timeOffset: 1,
      },
      true,
    );
  });
};

onBeforeMount(() => {
});

onMounted(() => {
  animacionesTextNavbar();
  playCronoetro();
  // document.querySelector('#maquina_header').play();
});
</script>

<style scoped lang="scss">
</style>
