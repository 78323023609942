<template>
  <div @mousemove="seguir()" class="container-fluid bg-white p-0 position-relative"
    style="min-height: 100vh; overflow-x: hidden; overflow-y: hidden;">
    <div class="hoy-none">
      <div class="row w-100 m-0 justify-content-center">
        <div class="col-11 p-0">
          <h1 class="text-intro cyberdyne">
            <span>{{ datos.hoyNormal.monnth }}</span>
            <span>/</span>
            <span>{{ datos.hoyNormal.day }}</span>
            <span>/</span>
            <span class="hoy-intro">{{ datos.hoyNormal.year }}</span>
          </h1>
        </div>
      </div>
    </div>

    <div class="loader">
      <div class="big-bang-none" style="display: none">
        <div class="row w-100 m-0 justify-content-center">
          <div class="col-11 p-0">
            <h1 class="text-intro cyberdyne">
              <span>{{ datos.hoyTem.monnth }}</span>
              <span>/</span>
              <span>{{ datos.hoyTem.day }}</span>
              <span>/</span>
              <span class="big-bang-intro">{{ datos.hoyTem.year }}</span>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="big-bang-video" style="display: none; z-index: 100;">
      <div class="row w-100 m-0 justify-content-center">
        <div class="col-11 p-0">
          <h1 class="text-intro text-white text-opacity-75 cyberdyne">
            {{ `${datos.hoyTem.monnth}/${datos.hoyTem.day}/${datos.hoyTem.year}` }}
          </h1>
        </div>
      </div>
    </div>

    <div v-if="datos.playIntro" id="play-intro">
      <button @click="video()" type="button" class="btn">
        <figure role="button" class="figure m-0">
          <img src="../assets/images/LogoPlay.svg"
            class="figure-img img-fluid m-0" alt="" style="min-width: 3.2rem;">
        </figure>
        <!-- <BootstrapIcon class="m-0" icon="play-circle" size="3x" variant="light" flip-v /> -->
      </button>
    </div>

    <div class="video-intro position-absolute" style="z-index: 0;">
      <video id="intro" height="100%" preload="metadata">
        <source src="../assets/videos/master_intro_con_cambio_texto.mp4" type="video/mp4" />
        Your browser does not support HTML video.
        <track src="" kind="captions" srclang="es" label="">
      </video>
    </div>

    <div class="d-flex justify-content-center bg-transparent fixed-bottom mb-5">
      <div v-if="datos.mute" class="p-2">
        <!-- <button @click="mutedOf" type="button" class="btn btn-outline-secondary btn-sm">
          Listen
          <BootstrapIcon icon="volume-up" size="1x" variant="secondary" flip-v />
        </button> -->
      </div>
      <div v-else class="p-2">
        <!-- <button @click="mutedOn" type="button" class="btn btn-outline-secondary btn-sm">
          Mute
          <BootstrapIcon icon="volume-mute" size="1x" variant="secondary" flip-v />
        </button> -->
      </div>
      <div class="p-2">
        <button @click="skip" type="button" class="btn btn-outline-secondary btn-sm">
          Skip intro
          <BootstrapIcon icon="box-arrow-right" size="1x" variant="secondary" flip-v />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  onMounted,
  reactive,
  computed,
} from 'vue';
import Animation from '@/composables/Animate';
import $ from 'jquery';

const datos = reactive({
  mute: true,
  playIntro: false,
  hoyNormal: computed(() => {
    const date = new Date();
    const year = date.getFullYear();
    let monnth = date.getMonth() + 1;
    let day = date.getDate();
    if (monnth < 10) {
      monnth = `0${monnth}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }
    const fecN = {
      monnth,
      day,
      year,
    };
    return fecN;
  }),
  hoyTem: computed(() => {
    const date = new Date();
    let year = null;
    if (date.getFullYear() - 2023 === 0 && date.getFullYear() - 2023 < 10) {
      year = `000${date.getFullYear() - 2023}`;
    } else if (date.getFullYear() - 2023 > 10 && date.getFullYear() - 2023 < 100) {
      year = `00${date.getFullYear() - 2023}`;
    } else if (date.getFullYear() - 2023 > 100 && date.getFullYear() - 2023 < 1000) {
      year = `0${date.getFullYear() - 2023}`;
    } else {
      year = `${date.getFullYear() - 2023}`;
    }
    let monnth = date.getMonth() + 1;
    let day = date.getDate();
    if (monnth < 10) {
      monnth = `0${monnth}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }
    const fecT = {
      monnth,
      day,
      year,
    };
    return fecT;
  }),
  hora: computed(() => {
    const date = new Date().toLocaleTimeString('es-ES');
    return date;
  }),
});

const seguir = async () => {
  if (datos.playIntro) {
    const playIntro = document.getElementById('play-intro');
    const pageY = parseInt(window.event.pageY, 10) - 55;
    const pageX = parseInt(window.event.pageX, 10) - 40;
    playIntro.style.top = `${pageY}px`;
    playIntro.style.left = `${pageX}px`;
  }
};

/* const mutedOf = async () => {
  const intro = document.getElementById('intro');
  intro.muted = false;
  datos.mute = false;
};

const mutedOn = async () => {
  const intro = document.getElementById('intro');
  intro.muted = true;
  datos.mute = true;
}; */

const play = async () => {
  const intro = document.getElementById('intro');
  await intro.play();
};

const skip = async () => {
  window.location.replace('/home');
};

const video = async () => {
  await play();
  datos.playIntro = false;
  const bigBangVideo = document.querySelector('.big-bang-video');
  setTimeout(async () => {
    bigBangVideo.style.display = 'none';
  }, 1500);
  const intro = document.getElementById('intro');
  intro.addEventListener('timeupdate', async () => {
    if (intro.currentTime >= 77) {
      await intro.pause();
      await skip();
    }
  });
};

const hoyIntroAnimation = async (hoyIntro) => {
  setTimeout(async () => {
    hoyIntro.forEach((h) => {
      Animation.WordShuffler(
        h,
        {
          textColor: 'rgba(0,0,0,0.9)',
          timeOffset: 20,
          mixCapital: false,
          mixSpecialCharacters: false,
        },
        true,
        true,
      );
    });
  }, 6000);
};

const tempolanIntroAnimation = async () => {
  const hoyNone = document.querySelector('.hoy-none');
  const bigBangNone = document.querySelector('.big-bang-none');
  const bigBangIntro = document.querySelectorAll('.big-bang-intro');
  setTimeout(async () => {
    hoyNone.style.display = 'none';
    bigBangNone.style.display = 'block';
    bigBangIntro.forEach((b) => {
      Animation.WordShuffler(
        b,
        {
          textColor: 'rgba(255,255,255,0.7)',
          timeOffset: 20,
          mixCapital: false,
          mixSpecialCharacters: false,
        },
        true,
        true,
      );
    });
  }, 9000);
};

const loaderSpinner = async () => {
  const loader = $('.loader');
  const hoyIntro = document.querySelectorAll('.hoy-intro');

  await hoyIntroAnimation(hoyIntro);

  await $(window).on('load', () => {
    const wHeight = $(window).height();
    const wWidth = $(window).width();
    let i = 0;
    /* Center loader on half screen */
    loader.css({
      top: wHeight / 2 - 2.5,
      left: wWidth / 2 - 200,
    });

    do {
      loader.animate(
        {
          width: i,
          left: 0,
        },
        20,
      );
      i += 2;
    } while (i <= 400);
    if (i === 402) {
      loader.animate({
        left: 0,
        width: '100%',
      });
      loader.animate({
        top: '0',
        height: '100vh',
      });
    }
  });

  await tempolanIntroAnimation();

  /* This line hide loader and show content */
  setTimeout(async () => {
    document.querySelector('.container-fluid').classList.remove('bg-white');
    document.querySelector('.container-fluid').classList.add('bg-black');
    const bigBangVideo = document.querySelector('.big-bang-video');
    bigBangVideo.style.display = 'block';
    loader.fadeOut('fast');
    await $('.video-intro').fadeIn('slow');
    datos.playIntro = true;
  }, 10500);
};

onMounted(async () => {
  loaderSpinner();
});

</script>

<style scoped lang="scss">
#play-intro {
  position: absolute;
  z-index: 10;
  height: 4rem;
  width: 4rem;
  background: rgba(255, 255, 255, 0);
  border-radius: 15rem;
  top: 50%;
  left: 50%;
  padding: 2px 0 0 0;
}

.loader {
  position: absolute;
  left: 0;
  height: 1em;
  background: #000;
}

.video-intro {
  height: 100vh;
  min-width: 100%;
  background: rgb(0, 0, 0);
  display: none;
}

.hoy-none {
  position: absolute;
  min-width: 100%;
  font-size: 2em;
  top: 30%;
  transform: translateY(-30%);
}

.article,
.big-bang-none {
  background: transparent;
  width: 100%;
  position: absolute;
  font-size: 2em;
  top: 33%;
  transform: translateY(-50%);
}

.big-bang-video {
  background: transparent;
  width: 100%;
  position: absolute;
  font-size: 2em;
  top: 33%;
  transform: translateY(-50%);
}

.text-intro {
  font-size: 1.2em;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .hoy-none {
    font-size: 2em;
  }

  .article,
  .big-bang-none {
    font-size: 2em;
  }

  .big-bang-video {
    font-size: 2em;
  }

  .text-intro {
    font-size: 1.8em;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .hoy-none {
    font-size: 2em;
  }

  .article,
  .big-bang-none {
    font-size: 2em;
  }

  .big-bang-video {
    font-size: 2em;
  }

  .text-intro {
    font-size: 2.6em;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .hoy-none {
    font-size: 2em;
  }

  .article,
  .big-bang-none {
    font-size: 2em;
  }

  .big-bang-video {
    font-size: 2em;
  }

  .text-intro {
    font-size: 3.4em;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .hoy-none {
    font-size: 2em;
  }

  .article,
  .big-bang-none {
    font-size: 2em;
  }

  .big-bang-video {
    font-size: 2em;
  }

  .text-intro {
    font-size: 4.4em;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .text-intro {
    font-size: 5.2em;
  }
}

.btn-outline-secondary {
  --bs-btn-border-radius: 0;
}

.typing-intro {
  width: 22ch;
  animation: typing 3s steps(22), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-size: 2em;
  color: rgba(255, 255, 255, 0.7);
}

@keyframes typing {
  from {
    width: 0
  }
}

@keyframes blink {
  50% {
    border-color: transparent
  }
}
</style>
