<template>
  <NavBar :leng="datos.leng" />

  <div class="container p-5">
    <div class="card bg-black pt-5">
      <div class="card-body px-0">
        <div class="row align-items-center py-5">
          <div
            class="col-auto py-5"
            @mouseover="logoFaq(true)"
            @focus="logoFaq(true)"
            @mouseout="logoFaq(false)"
            @blur="logoFaq(false)"
          >
            <figure class="figure">
              <img v-if="datos.faqlogo" src="../assets/images/logo_180.gif"
                class="figure-img img-fluid logo-2" alt="">
              <img v-else src="../assets/images/logo.gif"
                class="figure-img img-fluid logo-2" alt="">
              <figcaption class="figure-caption"></figcaption>
            </figure>
          </div>
          <div class="col-auto py-5">
            <h1 v-if="props.leng === 'zh'" class="text-white text-opacity-75 futurism">
              常见问题
            </h1>
            <h1 v-else class="text-white text-opacity-75 futurism">
              FAQ
            </h1>
          </div>
        </div>
        <div class="accordion">
          <div class="accordion-item">
            <div class="accordion-header border-top border-bottom border-secondary">
              <button class="accordion-button collapsed ps-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="false"
              >
                <h4 v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 fw-bold futurism m-0">
                  什么是 TEMPOLAND?
                </h4>
                <h4 v-else
                  class="text-white text-opacity-75 fw-bold futurism m-0">
                  WHAT IS TEMPOLAND?
                </h4>
              </button>
            </div>
            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse">
              <div class="accordion-body py-5">
                <p v-if="props.leng === 'zh'" class="text-white text-opacity-75 sofiaExtraLight">
                  Tempoland 是一块新的可收藏土地，它将时间维度添加到正在创建的元界上。
                  它革命性的螺旋式日历覆盖了一个世纪的时间，即 <span class="fw-bold">100</span> 年，
                  <span class="fw-bold">1,200</span> 个月和
                  <span class="fw-bold">3,652</span> 天。每一个 <span class="fw-bold">Tempoland</span>
                  日期代表着一系列事件。
                </p>
                <p v-else class="text-white text-opacity-75 sofiaExtraLight">
                  Tempoland is a new collectible land that adds the dimension of time to the
                  spaces with which the metaverse is being created. Its revolutionary spiral
                  calendar covers a period of a century, i.e. 100 years, 1,200 months and
                  <span class="fw-bold">36,525 days.</span> Each Tempoland date represents
                  a series of events.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header border-top border-bottom border-secondary">
              <button
                class="accordion-button collapsed ps-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="false"
              >
                <h4 v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 futurism m-0">
                  我在哪里可以获得 Tempoland 上的一天，以及它的花费是多少？
                </h4>
                <h4 v-else
                  class="text-white text-opacity-75 futurism m-0">
                  WHERE CAN I GET A DAY ON TEMPOLAND AND HOW MUCH DOES IT COST?
                </h4>
              </button>
            </div>
            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingTwo">
              <div class="accordion-body py-5">
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 pt-4 sofiaExtraLight">
                  最初的 <span class="fw-bold">3,652</span> 天将在我们的网站私下铸造，价格为每天
                  <span class="fw-bold">0.3ETH</span>。之后它们将上市销售。
                </p>
                <p v-else
                  class="text-white text-opacity-75 pt-4 sofiaExtraLight">
                  The first 3,652 days will be minted privately on our website at a price of
                  0.3ETH/day. Later on they will go on public sale.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="accordion-header border-top border-bottom border-secondary">
              <button
                class="accordion-button collapsed ps-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseThree"
                aria-expanded="false"
              >
                <h4 v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 futurism m-0">
                  我在哪里可以获得 Tempoland 上的一个月或一年，以及它的花费是多少？
                </h4>
                <h4 v-else
                  class="text-white text-opacity-75 futurism m-0">
                  WHERE CAN I GET A MONTH OR A YEAR ON TEMPOLAND AND HOW MUCH DOES IT COST?
                </h4>
              </button>
            </div>
            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body py-5">
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 sofiaExtraLight">
                  最初的 120 个月和 10 年将在我们的网站私下铸造，价格很快就会公布。之后它们将上市销售。
                </p>
                <p v-else
                  class="text-white text-opacity-75 sofiaExtraLight">
                  The first 120 months and 10 years will be minted privately on our website at a
                  price to be announced soon. Later on they will go on public sale.
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <div class="accordion-header border-top border-bottom border-secondary">
              <button
                class="accordion-button collapsed ps-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseFor"
                aria-expanded="false"
              >
                <h4 v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 futurism m-0">
                  Tempoland 日的所有者有什么利益？
                </h4>
                <h4 v-else
                  class="text-white text-opacity-75 futurism m-0">
                  WHAT ARE THE PROFITS FOR THE OWNERS OF TEMPOLAND DAYS?
                </h4>
              </button>
            </div>
            <div id="panelsStayOpen-collapseFor" class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body py-5">
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 sofiaExtraLight">
                  Tempoland 的 "Day NFT" 的所有者已经选择了一个重要的日期，是时间和空间的所有者，
                  这些充满了可能性，但是尚未在我们的生态系统和它与现实世界的互动中定义。
                  他们将优先接触到其他即将到来的惊喜以及下一次 Croonos Lab 的收藏。
                </p>
                <p v-else
                  class="text-white text-opacity-75 sofiaExtraLight">
                  The owners of Tempoland's 'Day NFTs' have picked an important date and
                  are owners of a space-time full of possibilities, yet to be defined in
                  our ecosystem and in its interaction with the real world. They will have
                  priority access to other upcoming surprises and to the next Croonos Lab
                  collections.
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <div class="accordion-header border-top border-bottom border-secondary">
              <button
                class="accordion-button collapsed ps-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseFive"
                aria-expanded="false"
              >
                <h4 v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 fw-bold futurism m-0">
                  Tempoland 月或年的所有者有什么利益？
                </h4>
                <h4 v-else
                  class="text-white text-opacity-75 fw-bold futurism m-0">
                  WHAT ARE THE PROFITS FOR THE OWNERS OF TEMPOLAND MONTHS OR YEARS?
                </h4>
              </button>
            </div>
            <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body py-5">
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 fw-bold sofiaExtraLight">
                  按照我们的智能合约规定，Tempoland月NFT和年NFT的所有者将从其所在月或年出售的每一个
                  Day NFT中获得2％的版税。
                </p>
                <p v-else
                  class="text-white text-opacity-75 fw-bold sofiaExtraLight">
                  Owners of Tempoland Month NFTs and Year NFTs will receive a 2% royalty
                  on every Day NFT sold from their respective month or year.
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <div class="accordion-header border-top border-bottom border-secondary">
              <button
                class="accordion-button collapsed ps-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseSix"
                aria-expanded="false"
              >
                <h4 v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 futurism m-0">
                  什么是时间生物？
                </h4>
                <h4 v-else
                  class="text-white text-opacity-75 futurism m-0">
                  WHAT ARE TIME CREATURES?
                </h4>
              </button>
            </div>
            <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body py-5">
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 sofiaExtraLight">
                  时间生物是Tempoland生态系统的未来居民。他们的特定特征和生命特征将随着项目的发展而披露。
                </p>
                <p v-else
                  class="text-white text-opacity-75 sofiaExtraLight">
                  Time creatures are the future dwellers of the Tempoland ecosystem. Their
                  particular traits and life features will be disclosed as the project develops.
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <div class="accordion-header border-top border-bottom border-secondary">
              <button
                class="accordion-button collapsed ps-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseSeven"
                aria-expanded="false"
              >
                <h4 v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 futurism m-0">
                  什么是 TEMPOGÉNESIS?
                </h4>
                <h4 v-else
                  class="text-white text-opacity-75 futurism m-0">
                  WHAT IS TEMPOGÉNESIS?
                </h4>
              </button>
            </div>
            <div id="panelsStayOpen-collapseSeven" class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body py-5">
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 sofiaExtraLight">
                  Tempogenesis是一系列代表Tempo诞生的4D收藏品，Tempo是我们的第一个计时员。
                  它们将是我们的元世界中的第一批时间生物。这个集合已经在开发中。
                </p>
                <p v-else
                  class="text-white text-opacity-75 sofiaExtraLight">
                  Tempogenesis is a series of 4D collectibles representing the birth of Tempo, our
                  first time-keeper. They will be the first time creatures in our metaverse. This
                  collection is already being developed.
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <div class="accordion-header border-top border-bottom border-secondary">
              <button
                class="accordion-button collapsed ps-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseEight"
                aria-expanded="false"
              >
                <h4 v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 futurism m-0">
                  什么时候会推出Tempo？
                </h4>
                <h4 v-else
                  class="text-white text-opacity-75 futurism m-0">
                  WHEN WILL TEMPO BE LAUNCHED?
                </h4>
              </button>
            </div>
            <div id="panelsStayOpen-collapseEight" class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body py-5">
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 sofiaExtraLight">
                  Tempo目前正在通过纸上的笔记、草图和线条来确定其个性。我们可以预先透露，将会有一些部落，
                  每个部落都有一个显著的个性和不同的属性。我们会在适当的时候告诉您。
                </p>
                <p v-else
                  class="text-white text-opacity-75 sofiaExtraLight">
                  Tempo is currently defining its personality through notes, sketches and lines on
                  paper. We can advance that there will be a few tribes, each one of them with a
                  marked personality and different attributes. You will be informed in due time.
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <div class="accordion-header border-top border-bottom border-secondary">
              <button
                class="accordion-button collapsed ps-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseNine"
                aria-expanded="false"
              >
                <h4 v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 futurism m-0">
                  Tempoland将会发展多远并且持续多久？
                </h4>
                <h4 v-else
                  class="text-white text-opacity-75 futurism m-0">
                  HOW FAR AWAY AND FOR HOW LONG WILL TEMPOLAND GROW?
                </h4>
              </button>
            </div>
            <div id="panelsStayOpen-collapseNine" class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body py-5">
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 sofiaExtraLight">
                  请耐心等待，在Croonos Lab中，一切都是时间问题。我们已经在Tempoland宇宙上工作了一年多；
                  我们正在扩大团队，全力投入。Tempoland的目标是成为虚拟世界中的必要工具。目前，没有任何限制。
                </p>
                <p v-else
                  class="text-white text-opacity-75 sofiaExtraLight">
                  Be patient, in Croonos Lab everything is a matter of time. We have been working
                  on the Tempoland universe for more than a year; we are expanding our team and
                  devoting all our time to it. Tempoland aims to become an essential tool in virtual
                  worlds. For now, there are no limits.
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <div class="accordion-header border-top border-bottom border-secondary">
              <button
                class="accordion-button collapsed ps-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTem"
                aria-expanded="false"
              >
                <h4 v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 futurism m-0">
                  什么是Croonos Lab？
                </h4>
                <h4 v-else
                  class="text-white text-opacity-75 futurism m-0">
                  WHAT IS CROONOS LAB?
                </h4>
              </button>
            </div>
            <div id="panelsStayOpen-collapseTem" class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body py-5">
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 sofiaExtraLight">
                  Chroonos Lab是一支由不同背景的专业人员组成的团队，他们有着共同的愿景：创造一个去中心化的未来，
                  这将需要超越3D设计。实体世界和虚拟世界正在融合，时间的表现尚未得到探索。
                </p>
                <p v-else
                  class="text-white text-opacity-75 sofiaExtraLight">
                  Chroonos Lab is a team of professionals with different backgrounds and a common
                  vision: the creation of a decentralized future that will require going far beyond
                  3D design. Physical and virtual worlds are blending, and the representation of
                  time is yet to be explored.
                </p>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <div class="accordion-header border-top border-bottom border-secondary">
              <button
                class="accordion-button collapsed ps-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseEleven"
                aria-expanded="false"
              >
                <h4 v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 futurism m-0">
                  我可以加入Chroonos Lab团队吗？
                </h4>
                <h4 v-else
                  class="text-white text-opacity-75 futurism m-0">
                  CAN I JOIN CHROONOS LAB TEAM?
                </h4>
              </button>
            </div>
            <div id="panelsStayOpen-collapseEleven" class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body py-5">
                <p v-if="props.leng === 'zh'"
                  class="text-white text-opacity-75 sofiaExtraLight">
                  Chroonos Lab重视人才，并有着长期的项目。我们接受提案，并将在不久的将来发布新的工作机会。
                </p>
                <p v-else
                  class="text-white text-opacity-75 sofiaExtraLight">
                  Chroonos Lab values talent and has a long-term project. We are open to receive
                  proposals and we will be publishing new job oportunities soon.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid pt-5 pb-0 pe-0 ps-0">
    <div class="card bg-black">
      <div class="card-body p-0 position-relative">
        <div
          class="row justify-content-center align-items-center h-100 w-100 pt-5 position-absolute"
        >
          <div class="col-8 mt-5">
            <div class="row justify-content-center pt-5">
              <div class="col-auto pt-5">
                <ul v-if="props.leng === 'zh'" class="nav justify-content-center">
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{path: '/zh/team'}">
                      <h4 class="text-white text-opacity-75 futurism">
                        团队
                      </h4>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{path: '/zh/faq'}">
                      <h4 class="text-white text-opacity-75 futurism">
                        常见问题
                      </h4>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{path: '/zh/team', replace: true}">
                      <h4 class="text-white text-opacity-75 futurism">
                        白皮书
                      </h4>
                    </router-link>
                  </li>
                </ul>
                <ul v-else class="nav justify-content-center">
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{path: '/team'}">
                      <h4 class="text-white text-opacity-75 futurism">
                        TEAM
                      </h4>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{path: '/faq'}">
                      <h4 class="text-white text-opacity-75 futurism">
                        FAQ
                      </h4>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{path: '/team', replace: true}">
                      <h4 class="text-white text-opacity-75 futurism">
                        WHITEPAPER
                      </h4>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row justify-content-center p-5">
              <div class="col-auto">
                <figure class="figure p-0 m-0">
                  <img src="../assets/images/croonos_faq.svg"
                    class="figure-img img-fluid" alt="" style="height: 5rem;">
                  <figcaption class="figure-caption"></figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-0">
            <figure class="figure p-0 m-0" style="min-width:100%;">
              <img src="../assets/images/cam4verde.png"
                class="figure-img img-fluid" alt="" style="min-width:100%;">
              <figcaption class="figure-caption"></figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer2Section :leng="datos.leng" />
</template>

<script setup>
import {
  onBeforeMount,
  onMounted,
  reactive,
} from 'vue';
import { useRoute } from 'vue-router';
import NavBar from '@/components/NavBar.vue';
import Footer2Section from '@/components/Footer2Section.vue';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';

gsap.registerPlugin(ScrollTrigger, TextPlugin);
const route = useRoute();

const props = defineProps({
  leng: {
    type: String,
  },
});

const datos = reactive({
  faqlogo: false,
  leng: '',
});

const leng = async () => {
  if (route.params.leng === 'zh') {
    datos.leng = 'zh';
  }
};

const logoFaq = (el) => {
  datos.faqlogo = el;
};

onBeforeMount(async () => {
});

onMounted(async () => {
  await leng();
});
</script>

<style scoped lang="scss">
</style>
