import { createRouter, createWebHistory } from 'vue-router';
import IntroductionView from '../views/IntroductionView.vue';
import HomeView from '../views/HomeView.vue';
import TeamView from '../views/TeamView.vue';
import FaqView from '../views/FaqView.vue';
import ContactView from '../views/ContactView.vue';

const routes = [
  {
    path: '/',
    redirect: '/introduction',
  },
  {
    path: '/:leng?/introduction',
    name: 'introduction',
    component: IntroductionView,
    props: true,
  },
  {
    path: '/:leng?/home',
    name: 'home',
    component: HomeView,
    props: true,
  },
  {
    path: '/:leng?/team',
    name: 'team',
    component: TeamView,
    props: true,
  },
  {
    path: '/:leng?/faq',
    name: 'faq',
    component: FaqView,
    props: true,
  },
  {
    path: '/:leng?/contact',
    name: 'contact',
    component: ContactView,
    props: true,
  },
];
// component: () => import('../views/AboutView.vue'),
const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    return { top: 0 };
  },
});
export default router;
