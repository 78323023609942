<template>
  <div class="container p-5" id="footer">
    <div class="row justify-content-md-center">
      <div col-12>
        <ul v-if="props.leng === 'zh'" class="nav justify-content-center">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '/zh/team' }">
              <h4 class="text-white text-opacity-75 sofiaExtraLight">
                团队
              </h4>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '/zh/faq' }">
              <h4 class="text-white text-opacity-75 sofiaExtraLight">
                常见问题
              </h4>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '/zh/team', replace: true }">
              <h4 class="text-white text-opacity-75 sofiaExtraLight">
                白皮书
              </h4>
            </router-link>
          </li>
        </ul>
        <ul v-else class="nav justify-content-center">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '/team' }">
              <h4 class="text-white text-opacity-75 sofiaExtraLight">
                TEAM
              </h4>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '/faq' }">
              <h4 class="text-white text-opacity-75 sofiaExtraLight">
                FAQ
              </h4>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '/team', replace: true }">
              <h4 class="text-white text-opacity-75 sofiaExtraLight">
                WHITEPAPER
              </h4>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="container-fluid pt-0 pb-5 pe-0 ps-0">
    <div class="card bg-black">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-12">
            <img src="../assets/images/croonos.svg"
              class="img-fluid" alt="" style="min-width: 100%;">
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--
  <div class="container-fluid p-0 text-center border-top border-bottom border-1 border-warning">
    <div class="row justify-content-md-center">
      <div class="col col-lg-2 pt-5 pb-5 ps-0 pe-0 my-auto">
        <img src="../assets/images/logo_eses.svg" class="mx-auto d-block" alt="">
      </div>
      <div class="col col-lg-2 pt-5 pb-5 ps-0 pe-0 my-auto">
        <img src="../assets/images/logo_local_disponible.svg" class="mx-auto d-block" alt="">
      </div>
      <div class="col col-lg-2 pt-5 pb-5 ps-0 pe-0 my-auto">
        <img src="../assets/images/logo_heredad.svg" class="mx-auto d-block" alt="">
      </div>
      <div class="col col-lg-2 pt-5 pb-5 ps-0 pe-0 my-auto">
        <img src="../assets/images/logo_rentals.svg" class="mx-auto d-block" alt="">
      </div>
      <div class="col col-lg-2 pt-5 pb-5 ps-0 pe-0 my-auto">
        <img src="../assets/images/logo_bon_vivant.svg" class="mx-auto d-block" alt="">
      </div>
    </div>
  </div>
  -->

  <!-- <div class="container-fluid p-5 border-top border-1 border-warning">
    <div class="row justify-content-md-center">
      <div col-12>
        <div class="container">
          <div class="row">
            <div col-12>
              <img src="../assets/images/rectngulo.png"
                class="d-block" alt="" style="width: 12rem;">
            </div>
            <div col-12>
              <h4 class="text-white text-opacity-75 sofiaExtraLight">
                © 2021 Croonos. Madrid, España hola@croonos.io
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script setup>
import {
  onBeforeMount,
  onMounted,
  // reactive,
} from 'vue';
// import Animation from '@/composables/Animate';

const props = defineProps({
  leng: {
    type: String,
  },
  navbar: {
    type: String,
  },
});

/* const animacionesTextFooter = async () => {
  const titlesHome = document.querySelectorAll('.anime-titles-footer');
  titlesHome.forEach((text) => {
    Animation.WordShuffler(
      text,
      {
        textColor: 'rgba(255,255,255,0.7)',
        timeOffset: 2,
        mixCapital: true,
        mixSpecialCharacters: true,
      },
      true,
    );
  });
  const parrafoHome = document.querySelectorAll('.anime-text-footer');
  parrafoHome.forEach((text) => {
    Animation.WordShuffler(
      text,
      {
        textColor: 'rgba(255,255,255,0.7)',
        timeOffset: 1,
      },
      true,
    );
  });
}; */

onBeforeMount(() => {
});

onMounted(() => {
});
</script>

<style scoped lang="scss">
.border-warning {
  --bs-border-opacity: 0.5 !important;
  --bs-warning-rgb: 255, 255, 0;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}
</style>
